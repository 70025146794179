import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from './../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount } from '../types';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';

type SalesQueryArgs = {
  [x: string]: any;
};

type SalesHistoryData = EntityWithCount<Vehicle> & {
  makes: string[];
  models: string[];
};

export const salesAdapter = createEntityAdapter<Vehicle>();
export const salesHistoryAdapter = createEntityAdapter<Vehicle>();

export const salesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMySales: builder.query<EntityWithCount<Vehicle>, SalesQueryArgs>({
      query: filters => ({
        url: 'seller/vehicles/sales',
        method: 'GET',
        params: { limit: 24, offset: 1, auctionStatus: 'active', ...filters }
      }),
      providesTags: ['Sales'],
      transformResponse: (response: any) => {
        return {
          rows: salesAdapter.addMany(salesAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg, baseQueryMeta }) => {
        const newVehicles = Object.values(newItems.rows.entities) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          salesAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          salesAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    getMySalesCounters: builder.query<{ active: number; pending: number }, void>({
      query: () => ({
        url: 'seller/vehicles/sales-counters',
        method: 'GET'
      }),
      providesTags: ['Sales'],
      transformResponse: (response: any) => response.data
    }),
    getSalesHistory: builder.query<SalesHistoryData, SalesQueryArgs>({
      query: ({ limit, offset, ...filters }) => ({
        url: 'seller/vehicles/sales-history',
        method: 'GET',
        params: { limit, offset, ...filters }
      }),
      providesTags: ['Sales'],
      transformResponse: (response: any) => {
        return {
          rows: salesHistoryAdapter.addMany(salesHistoryAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count),
          makes: response.data.makes || [],
          models: response.data.models || []
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = Object.values(newItems.rows.entities) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          salesHistoryAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          salesHistoryAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    })
  }),
  overrideExisting: true
});

export const { useGetMySalesQuery, useGetMySalesCountersQuery, useGetSalesHistoryQuery } = salesApiSlice;

export const useGetMySalesQueryState = salesApiSlice.endpoints.getMySales.useQueryState;
