import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { updateAdminNotes } from '../../actions/vehicles';
import FormTextField from '../Form/FormTextField';
import { maxLength, validateMinBid, validateReservePrice } from '../../utils/validators';
import { normalizeFormattedField } from '../../utils';
import { FormFormattedField } from '../Form';
import { getMinBitAmount } from 'utils/vehicle';
import { FakeUserDropdown } from 'components/FakeUserDropdown';
import { useDispatch } from 'react-redux';

const maxNotesLength = maxLength(50);

const validateFakeBid = (vehicle, prefix) => {
  return (value, values) => {
    if (!value || !values[`${prefix}_fake_user_id`]) return;
    const minBid = getMinBitAmount(vehicle);
    if (value < minBid) {
      return `Amount too low`;
    }
  };
};

const StyledFieldRow = styled.div`
  display: flex;
  margin: 15px 0;
  gap: 8px;
  & > div {
    flex: 1 1 100%;
  }
`;

const NotesModal = ({ modalId, vehicle, initialValues }) => {
  const dispatch = useDispatch();
  const handleSubmit = values => {
    const fakeBid =
      checkFakeBid(values, 'open_lane') || checkFakeBid(values, 'acv') || checkFakeBid(values, 'other') || null;

    dispatch(
      updateAdminNotes({
        id: vehicle.id,
        admin_notes: values.adminNotes,
        reserve_price: values.reserve_price || null,
        starting_bid: values.starting_bid || 100,
        acv_offers: createCompanyInput(values, 'acv'),
        other_offers: createCompanyInput(values, 'other'),
        open_lane_offers: createCompanyInput(values, 'open_lane'),
        fake_bid: fakeBid
      }).request
    );
  };

  return (
    <Form onSubmit={handleSubmit} keepDirtyOnReinitialize initialValues={initialValues}>
      {props => (
        <ModalWrapper
          title="Notes & Reserve"
          modalId={modalId}
          submitText="Save"
          className="notes-dialog"
          cancelAdditionalAction={() => {
            props.form.reset();
          }}
          handleSubmit={props.handleSubmit}
          onClose={() => {
            props.form.reset();
          }}
        >
          <StyledFieldRow>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Admin notes</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="adminNotes"
                  component={FormTextField}
                  margin="dense"
                  placeholder="Admin notes"
                  type="text"
                  fullWidth
                  validate={maxNotesLength}
                />
              </Grid>
            </Grid>
          </StyledFieldRow>
          <StyledFieldRow>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Reserve Price</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="reserve_price"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Reserve price"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                  validate={validateReservePrice}
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Starting Bid</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="starting_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Starting bid"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                  validate={validateMinBid}
                />
              </Grid>
            </Grid>
          </StyledFieldRow>
          <StyledFieldRow>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Open Lane</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="open_lane_opening_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Opening Bid"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="open_lane_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Bid"
                  type="text"
                  fullWidth
                  validate={validateFakeBid(vehicle, 'open_lane')}
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="open_lane_sold"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Sold"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <FakeUserDropdown companyName="open_lane" />
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">ACV</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="acv_opening_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Opening Bid"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="acv_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Bid"
                  type="text"
                  fullWidth
                  validate={validateFakeBid(vehicle, 'acv')}
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="acv_sold"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Sold"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <FakeUserDropdown companyName="acv" />
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Other</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="other_opening_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Opening bid"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="other_bid"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Bid"
                  type="text"
                  fullWidth
                  validate={validateFakeBid(vehicle, 'other')}
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="other_sold"
                  component={FormFormattedField}
                  thousandSeparator
                  allowNegative={false}
                  fixedDecimalScale
                  prefix="$"
                  margin="dense"
                  placeholder="Sold"
                  type="text"
                  fullWidth
                  parse={normalizeFormattedField}
                />
              </Grid>
              <Grid item xs={12}>
                <FakeUserDropdown companyName="other" />
              </Grid>
            </Grid>
          </StyledFieldRow>
        </ModalWrapper>
      )}
    </Form>
  );
};

const checkFakeBid = (values, prefix) => {
  const dealerKey = `${prefix}_fake_user_id`;
  const bidKey = `${prefix}_bid`;

  if (values[dealerKey] && Number(values[bidKey])) {
    return {
      user_id: values[dealerKey],
      amount: Number(values[bidKey])
    };
  }
};

const createCompanyInput = (values, prefix) => {
  return {
    opening_bid: Number(values[`${prefix}_opening_bid`]) || null,
    bid: Number(values[`${prefix}_bid`]) || null,
    sold: Number(values[`${prefix}_sold`]) || null,
    fake_user_id: Number(values[`${prefix}_fake_user_id`]) || null
  };
};

export default NotesModal;
