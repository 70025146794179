import { createSelector } from 'reselect';
// import createFilterOptions from "react-select-fast-filter-options";
import { getUsers } from './users';
import { legacyOfferAuctionSelectorWithMultipleIds } from 'services/api/offer-auction';
import { legacyAuctionVehiclesSelector } from 'services/api/admin-auction-vehicles';

const createFilterOptions = () => {};
export const getModalData = state => state.auctions.auctionVehiclesModal;

export const getBidders = state => state.auctions.auctionVehicles.bidders.data;

export const getIsBiddersLoaded = state => state.auctions.auctionVehicles.bidders.success;

export const getOfferAuction = state => {
  const auctions = legacyAuctionVehiclesSelector(state, state.auctions.auctionVehiclesModal.auction_id);
  return legacyOfferAuctionSelectorWithMultipleIds(state, auctions?.ids || [], state.auctions.auctionVehiclesModal?.id);
};

export const getBiddersAndUsers = createSelector(
  [getUsers, getModalData, getOfferAuction],
  (users, vehicle, offerAuction) => {
    const uniqueBidders = vehicle.bids.reduce((acc, { bidder }) => {
      const isBidderAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
      if (isBidderAlreadyExists || bidder.is_bot) return acc;
      acc.push({
        value: bidder.id,
        label: `Bidder: ${bidder.dealership_name}`
      });
      return acc;
    }, []);

    const uniqueOffers =
      offerAuction?.offerBids.reduce((acc, offerBid) => {
        const bidder = offerBid?.bidder;
        if (!bidder || bidder.is_bot) return acc;
        const isOfferAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
        const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === bidder.id);
        if (isOfferAlreadyExists || isBidderAlreadyExists) return acc;
        acc.push({
          value: bidder.id,
          label: `Bidder: ${bidder.dealership_name}`
        });
        return acc;
      }, []) || [];

    const usersWithoutBidders = users.reduce((acc, user) => {
      const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === user.id);
      const isOfferAlreadyExists = uniqueOffers.find(existingBidder => existingBidder.value === user.id);
      if (isBidderAlreadyExists || isOfferAlreadyExists) return acc;
      acc.push({
        value: user.id,
        label: user.dealership_name
      });
      return acc;
    }, []);

    return [...uniqueBidders, ...uniqueOffers, ...usersWithoutBidders];
  }
);

export const getIndexedBiddersAndUsers = createSelector(
  [getUsers, getModalData, getOfferAuction],
  (users, vehicle, offerAuction) => {
    const uniqueBidders = vehicle.bids.reduce((acc, { bidder }) => {
      const isBidderAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
      if (isBidderAlreadyExists) return acc;
      acc.push({
        value: bidder.id,
        label: `Bidder: ${bidder.dealership_name}`
      });
      return acc;
    }, []);

    const uniqueOffers =
      offerAuction?.offerBids.reduce((acc, offerBid) => {
        const bidder = offerBid?.bidder;
        if (!bidder) return acc;
        const isOfferAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
        const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === bidder.id);
        if (isOfferAlreadyExists || isBidderAlreadyExists) return acc;
        acc.push({
          value: bidder.id,
          label: `Bidder: ${bidder.dealership_name}`
        });
        return acc;
      }, []) || [];

    const usersWithoutBidders = users.reduce((acc, user) => {
      const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === user.id);
      const isOfferAlreadyExists = uniqueOffers.find(existingBidder => existingBidder.value === user.id);
      if (isBidderAlreadyExists || isOfferAlreadyExists) return acc;
      acc.push({
        value: user.id,
        label: user.dealership_name
      });
      return acc;
    }, []);

    return createFilterOptions({
      options: [...uniqueBidders, ...uniqueOffers, ...usersWithoutBidders]
    });
  }
);
