import apiSlice from '..';
import { User } from '../../../types/user';

export const adminUsersApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createFakeUser: builder.mutation<{ data: User }, { dealership_name: string; company_name: string }>({
      query: payload => ({
        url: `/admin/users/fake`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['FakeUsers']
    }),
    updateFakeUser: builder.mutation<void, { dealership_name: string; company_name: string; id: number }>({
      query: ({ id, ...payload }) => ({
        url: `/admin/users/fake/${id}`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['FakeUsers', 'AuctionVehicles']
    }),
    deleteFakeUser: builder.mutation<void, number>({
      query: id => ({
        url: `/admin/users/fake/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['FakeUsers', 'AuctionVehicles']
    }),
    getFakeUsers: builder.query<{ acv: User[]; other: User[]; open_lane: User[] }, void>({
      query: () => ({
        url: `/admin/users/fake`,
        method: 'GET'
      }),
      providesTags: ['FakeUsers'],
      transformResponse: (r: any) => r.data
    }),
    getFakeUserList: builder.query<User[], void>({
      query: () => ({
        url: 'admin/users/fake/list',
        method: 'GET'
      }),
      providesTags: ['FakeUsers'],
      transformResponse: (r: any) => r.data
    })
  }),
  overrideExisting: true
});

export const {
  useGetFakeUsersQuery,
  useCreateFakeUserMutation,
  useGetFakeUserListQuery,
  useUpdateFakeUserMutation,
  useDeleteFakeUserMutation
} = adminUsersApi;
export default adminUsersApi;
