import styled from 'styled-components';
import { useIsOrderLockedQuery, useToggleOrderLockMutation } from '../../../../services/api/admin-auction-vehicles';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { modalsToggle, snackShow } from '../../../../actions';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';

const StyledButton = styled.button`
  height: 32px;
  width: 100px;
  border-radius: 6px;
  background: #3994de;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
`;

export const ToggleLockButton = () => {
  const { id: auctionId } = useParams<{ id: string }>();
  const [toggleOrderLock, { isLoading }] = useToggleOrderLockMutation();
  const { data: isLocked, isLoading: queryLoading, isError } = useIsOrderLockedQuery(Number(auctionId), {
    skip: !auctionId
  });
  const dispatch = useDispatch();

  const action = async () => {
    const prevLocked = isLocked;
    await toggleOrderLock(Number(auctionId));
    const notification = prevLocked ? 'Unlocked numbers successfully' : 'Locked numbers successfully';
    dispatch(modalsToggle('confirm-lock'));
    dispatch(snackShow({ message: notification }));
  };

  const handleClick = () => {
    dispatch(modalsToggle(`confirm-lock`));
  };

  if (queryLoading || isError) {
    return null;
  }

  const operationName = isLocked ? 'Unlock' : 'Lock';

  return (
    <>
      <StyledButton disabled={isLoading} type="button" onClick={handleClick}>
        {`${operationName} numbers`}
      </StyledButton>
      <ConfirmModal
        title={`Are you sure you want to ${operationName.toLowerCase()} numbers?`}
        handleSubmit={action}
        submitText={'Confirm'}
        modalId={'confirm-lock'}
      />
    </>
  );
};
