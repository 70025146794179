import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketMessage } from '../api/types';
import {
  WS_VEHICLE_TIME_END,
  WS_VEHICLE_NOTES_UPDATE,
  WS_NEW_PROXY,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_NEW_BID,
  WS_MY_BID_UPDATE,
  WS_FIRST_BID
} from 'constants/actionTypes';
import { bidsAdapter, myBidsApiSlice as api, useMyBidsQueryState } from 'services/api/my-bids';
import getSocket from '.';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const useMyBidsSocketEffect = () => {
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>;
  const { page, perPage, filters } = useSelector((state: any) => state.myBids);
  const user = useSelector((state: any) => state.user.user);

  const { data } = useMyBidsQueryState({
    limit: perPage,
    offset: page,
    ...filters
  });

  const listener = ({ payload, type }: SocketMessage) => {
    console.log({ payload, type }, 'socket message');
    if (type === WS_MY_BID_UPDATE) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            bidsAdapter.updateOne(draft.rows, {
              id: payload.vehicle_id,
              changes: {
                my_bid: payload
              }
            });
          }
        )
      );
    }

    if (type === WS_NEW_BID) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            bidsAdapter.updateOne(draft.rows, {
              id: payload.vehicle_id,
              changes: {
                highest_bid: payload
              }
            });
          }
        )
      );
    }

    if (type === WS_NEW_PROXY) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            bidsAdapter.updateOne(draft.rows, {
              id: payload.vehicle_id,
              changes: {
                proxy_bid: payload
              }
            });
          }
        )
      );
    }

    if (type === WS_VEHICLE_DETAILS_UPDATE) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            bidsAdapter.updateOne(draft.rows, { id: payload.id, changes: payload });
          }
        )
      );
    }

    if (type === WS_VEHICLE_NOTES_UPDATE) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            bidsAdapter.updateOne(draft.rows, {
              id: payload.vehicleId,
              changes: { notes: payload.notes }
            });
          }
        )
      );
    }

    if (type === WS_VEHICLE_TIME_END) {
      dispatch(
        api.util.updateQueryData(
          'getMyBids',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            const hasBids = payload && payload.bids && payload.bids.some((bid: any) => bid.user_id === user.id);
            console.log(payload, 'vehicle timer ended');
            if (!hasBids) return;

            const exists = bidsAdapter.getSelectors().selectById(draft.rows, payload.id);
            const shouldRemove = filters.auctionStatus === 'active';
            console.log(filters.auctionStatus, exists, 'time ended my bids');
            if (shouldRemove && exists) {
              bidsAdapter.removeOne(draft.rows, payload.id);
              draft.count -= 1;
            } else {
              bidsAdapter.upsertOne(draft.rows, payload);
            }

            dispatch(
              api.util.updateQueryData('getMyBidsCounters', undefined, counterDraft => {
                counterDraft.pending += 1;
                counterDraft.active -= 1;
              })
            );
          }
        )
      );
    }

    // if (type === WS_FIRST_BID) {
    //   dispatch(api.util.invalidateTags(['MyBids', 'MyBidsCounters']));
    // }
  };

  useEffect(() => {
    if (user.role !== 'buyer') return;
    if (!data) return;
    const vehicleIds = data?.rows?.ids ?? [];
    const socket = getSocket();

    socket.emit('subscribe', {
      prefix: `vehicles`,
      ids: vehicleIds
    });
    socket.emit('subscribe', {
      prefix: `vehicles-notes:${user.id}`,
      ids: vehicleIds
    });
    // socket.emit('subscribe', {
    //   prefix: `user`,
    //   ids: [user.id]
    // });
    socket.on('message', listener);

    return () => {
      socket.emit('unsubscribe', {
        prefix: `vehicles`,
        ids: vehicleIds
      });
      socket.emit('unsubscribe', {
        prefix: `vehicles-notes:${user.id}`,
        ids: vehicleIds
      });
      socket.off('message', listener);
    };
  }, [dispatch, data?.rows?.ids, perPage, page, filters, user.id]);
};
