import { useHistory } from 'react-router-dom';
import { OfferAuction, OfferAuctionStatus, Vehicle } from '../../../../../types/vehicle';
import { SELLER_VEHICLE_STATUS } from '../../../../../constants';
import { modalsToggle } from '../../../../../actions';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useScreen } from 'usehooks-ts';

export const useSalesActions = (
  currentVehicle: Vehicle,
  offerAuction: OfferAuction | null | undefined,
  offerAuctionStatus: string
) => {
  const { width } = useScreen() || { width: 0 };
  const history = useHistory();
  const dispatch = useDispatch();

  const highestBid = currentVehicle?.highest_bid;

  const isMobile = width < 567;

  const buttons = [
    {
      title: 'View',
      color: 'secondary',
      className: 'text-center',
      handleClick: (item: Vehicle) => history.push(`/vehicles/${item.id}/details`),
      fullWidth: true,
      withShadow: true,
      isRounded: true,
      size: isMobile ? 'lg' : 'auto'
    },
    {
      title: 'Drop Reserve',
      color: 'light',
      conditionalRendering: (item: Vehicle) => {
        return (
          offerAuctionStatus === SELLER_VEHICLE_STATUS.IN_PROGRESS && item.reserve_price_set && !item.reserve_price_met
        );
      },
      handleClick: (item: Vehicle) => {
        dispatch(modalsToggle(`dropReserveModal-${item.id}`));
      },
      fullWidth: true,
      withShadow: true,
      isRounded: true,
      size: isMobile ? 'lg' : 'auto'
    },
    {
      title: 'Counter',
      color: 'light',
      conditionalRendering: (item: Vehicle) => {
        return [
          SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW,
          SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT,
          SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS,
          SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL,
          SELLER_VEHICLE_STATUS.SELLER_ACTION_REQUIRED
        ].includes(offerAuctionStatus);
      },
      handleClick: (item: Vehicle) => {
        dispatch(modalsToggle(`sendVehicleOffer-${item.id}`));
      },
      fullWidth: true,
      withShadow: true,
      isRounded: true,
      size: isMobile ? 'lg' : 'auto'
    },
    {
      title: 'Accept',
      color: 'primary',
      conditionalRendering: (item: Vehicle) => {
        if (offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW) {
          return highestBid?.amount > 0 && !highestBid?.bidder?.is_bot;
        }

        const highestOffer = offerAuction?.offerBids?.[0];
        if (highestOffer && highestOffer.bidder.is_bot) {
          return false;
        }

        return [
          SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT,
          SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS,
          SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS,
          SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW,
          SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL,
          SELLER_VEHICLE_STATUS.SELLER_ACTION_REQUIRED
        ].includes(offerAuctionStatus);
      },

      handleClick: (item: Vehicle) => {
        const buyerOffers = offerAuction?.privateOffers?.filter(offer => offer.sender?.role === 'buyer');
        if (
          (offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW || buyerOffers?.length === 0) &&
          offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS
        ) {
          dispatch(modalsToggle(`acceptCounterModal-${item.id}`));
        } else if (
          [
            SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW,
            SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS,
            SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL,
            SELLER_VEHICLE_STATUS.SELLER_ACTION_REQUIRED
          ].includes(offerAuctionStatus)
        ) {
          dispatch(modalsToggle(`acceptTopOffer-${item.id}`));
        } else {
          dispatch(modalsToggle(`acceptPrivateOffer-${item.id}`));
        }
      },
      fullWidth: true,
      withShadow: true,
      isRounded: true,
      size: isMobile ? 'lg' : 'auto'
    }
  ];

  return buttons;
};
