import { put, call, takeEvery, takeLatest, all, select, delay } from 'redux-saga/effects';
import { initialize, stopSubmit, change, SubmissionError, reset } from 'redux-form';
import { goBack, push } from 'connected-react-router';
import {
  addVehicle,
  updateVehicle,
  listVehicles,
  modalsToggle,
  snackShow,
  vehicleDetails,
  requestInProgress,
  modalsHide,
  listBids
} from '../../actions';
import {
  CHANGE_VEHICLE_TRIM,
  getVinInfo,
  getCarEstimate,
  VEHICLE_MANUAL_UPLOAD_REQUEST,
  vehicleManualUpload,
  VEHICLE_FORM_INIT_REQUEST,
  vehicleFormInit,
  setVehicleImages,
  UPLOAD_VEHICLE_IMAGE_REQUEST,
  uploadVehicleImage,
  AUCTION_DETAIL_SET_NOTES_SUCCESS
} from '../../actions/vehicles';
import {
  GET_VIN_INFO_REQUEST,
  CREATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_REQUEST,
  GET_CAR_ESTIMATE_REQUEST,
  UPDATE_ADMIN_NOTES_REQUEST
} from '../../constants/actionTypes';
import { VALIDATION_TYPES, vehicleDraftRequiredFields, vehicleSubmitRequiredFields } from '../../constants/validation';
import { Api, Auth } from '../../utils';
import scrollToError from '../../utils/scrollToError';
import { roles, UPLOAD_TYPES, VEHICLE_STATUSES, BIDS_PER_PAGE } from '../../constants';
import { VEHICLE_CREATION_DEFAULT_OPTIONS } from '../../constants/forms';
import { userRoleSelector } from '../../selectors';
import { getForm, removeForm } from '../../utils/vehicleForm';
import { getBackupToken } from '../../utils/auth';
import { adminAuctionVehiclesSlice } from 'services/api/admin-auction-vehicles';
import { salesApiSlice } from '../../services/api/sales';

function* createVehicle({ payload }) {
  try {
    yield put(requestInProgress(true));
    yield put(modalsToggle('loaderModal'));
    const { data } = yield call(Api.vehicles.createVehicle, payload);
    yield put(addVehicle(data).success);
    yield put(modalsToggle('loaderModal'));
    yield put(salesApiSlice.util.invalidateTags(['Sales']));
    yield put(snackShow({ hideTime: 3000, message: 'Vehicle uploaded.' }));
    removeForm();
    console.log('removed');

    yield put(
      push(
        data.data.upload_type === UPLOAD_TYPES.MANUAL
          ? `/vehicles/${data.data.id}/edit-manual`
          : `/vehicles/${data.data.id}/edit`
      )
    );
    yield put(requestInProgress(false));
  } catch (e) {
    console.warn(e);
    if (e.response) {
      const errors = e.response.data.data;
      if (errors) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        yield put(stopSubmit('addVehicleForm', validationErrors));
      }
      const message = errors && errors.length ? errors[0].message : e.response.data.message;
      yield put(snackShow({ message, type: 'error' }));
      yield put(addVehicle(message).failure);
    } else if (e.message === 'Network Error') {
      yield put(
        snackShow({
          message: 'Vehicle upload failed because of network failure, please try again',
          type: 'error',
          hideTime: 3000
        })
      );
      yield put(addVehicle('Vehicle upload failed because of network failure, please try again').failure);
    }
    yield put(modalsToggle('loaderModal'));
    yield put(requestInProgress(false));
  }
}

const editVehicleState = state => ({
  selectedVehicle: state.vehicles.selectedVehicle.data,
  role: state.user.user.role
});

const myBidsPagination = state => ({
  myBidsPerPage: parseInt(state.bids.perPage, 10) || BIDS_PER_PAGE
});

function* editVehicle({ payload }) {
  try {
    yield put(requestInProgress(true));
    yield put(modalsToggle('loaderModal'));
    const {
      updateText,
      redirect = true,
      refetchMyBids = false,
      refetchLiveAuction = false,
      updateOptions = true,
      ...updatePayload
    } = payload;
    const { data } = yield call(Api.vehicles.updateVehicle, updatePayload.id, updatePayload, { updateOptions });
    yield put(updateVehicle(data).success);

    if (refetchMyBids) {
      const { myBidsPerPage } = yield select(myBidsPagination);
      yield put(listBids({ params: { limit: myBidsPerPage, offset: 1 }, reset: true }).request);
    }

    if (refetchLiveAuction) {
      yield put(listVehicles({ params: { location_id: 1, offset: 0 } }).request);
    }

    yield put(salesApiSlice.util.invalidateTags(['Sales']));

    yield put(modalsToggle('loaderModal'));
    yield put(
      snackShow({
        hideTime: 3000,
        message:
          updateText ??
          `Vehicle ${
            payload.status === VEHICLE_STATUSES.DRAFT ||
            (payload.previous_status === VEHICLE_STATUSES.ACTIVE && payload.status === VEHICLE_STATUSES.ACTIVE)
              ? 'updated'
              : 'published'
          }`
      })
    );
    if (!redirect) return;
    if (payload.previous_status !== VEHICLE_STATUSES.DRAFT && payload.status !== VEHICLE_STATUSES.DRAFT) {
      yield put(goBack());
    } else if (data.data.status === VEHICLE_STATUSES.ACTIVE) {
      const state = yield select(editVehicleState);
      yield call(delay, 3000);
      if (state.role === 'admin') {
        if (state.selectedVehicle && state.selectedVehicle.id && state.selectedVehicle.auction_id) {
          yield put(push(`/admin/auctions/${state.selectedVehicle.auction_id}`));
        } else if (payload.status !== VEHICLE_STATUSES.DRAFT) {
          yield put(push('/admin/vehicles'));
        }
      } else if (payload.status !== VEHICLE_STATUSES.DRAFT) {
        yield put(push('/seller/vehicles'));
      }
    }
    yield put(requestInProgress(false));
  } catch (e) {
    if (e.response) {
      const errors = e.response.data.data;
      if (errors) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        yield put(stopSubmit('addVehicleForm', validationErrors));
      }
      const message = errors && errors.length ? errors[0].message : e.response.data.message;
      yield put(snackShow({ message, type: 'error' }));
    } else if (e.message === 'Network Error') {
      yield put(
        snackShow({
          message: 'Vehicle upload failed because of network failure, please try again',
          type: 'error',
          hideTime: 3000
        })
      );
    }
    yield put(modalsToggle('loaderModal'));
    yield put(updateVehicle(e).failure);
    yield put(requestInProgress(false));
  }
}

function* editAdminNotes({ payload }) {
  try {
    yield put(modalsToggle('loaderModal'));
    const {
      data: { data }
    } = yield call(Api.vehicles.updateAdminNotes, payload);

    yield put({
      type: AUCTION_DETAIL_SET_NOTES_SUCCESS,
      payload: {
        data: {
          id: data.id,
          admin_notes: data.admin_notes,
          reserve_price: data.reserve_price
        }
      }
    });
    yield put(adminAuctionVehiclesSlice.util.invalidateTags(['AuctionVehicles']));
    yield put(modalsToggle('loaderModal'));
    yield put(modalsHide('NotesForm'));
    yield put(snackShow({ message: 'Saved data' }));
  } catch (e) {
    if (e.response) {
      const errors = e.response.data.data;
      if (errors) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        yield put(stopSubmit('NotesForm', validationErrors));
      }
      const message = errors && errors.length ? errors[0].message : e.response.data.message;
      yield put(snackShow({ message, type: 'error' }));
    } else if (e.message === 'Network Error') {
      yield put(
        snackShow({
          message: 'Vehicle upload failed because of network failure, please try again',
          type: 'error',
          hideTime: 3000
        })
      );
    }
    yield put(modalsToggle('loaderModal'));
    yield put(updateVehicle(e).failure);
  }
}

function* initInitialData(data) {
  const {
    year,
    make,
    model,
    engine,
    ext_color,
    transmission,
    drive_train,
    options,
    starting_bid,
    reserve_price
  } = getForm();
  yield put(change('addVehicleForm', 'year', year || data.year));
  yield put(change('addVehicleForm', 'make', make || data.make));
  yield put(change('addVehicleForm', 'model', model || data.model));
  yield put(change('addVehicleForm', 'engine', engine || data.engine));
  yield put(change('addVehicleForm', 'ext_color', ext_color || data.ext_color));
  yield put(change('addVehicleForm', 'transmission', transmission || data.transmission));
  yield put(change('addVehicleForm', 'drive_train', drive_train || data.drive_train));
  yield put(change('addVehicleForm', 'options', options || data.options));
  yield put(change('addVehicleForm', 'starting_bid', starting_bid || data.starting_bid));
  yield put(change('addVehicleForm', 'reserve_price', reserve_price || data.reserve_price));
}

function* initDataAfterEdit(data, estimateOptions = []) {
  yield put(change('addVehicleForm', 'year', data.year));
  yield put(change('addVehicleForm', 'make', data.make));
  yield put(change('addVehicleForm', 'model', data.model));
  yield put(change('addVehicleForm', 'engine', data.engine));
  yield put(change('addVehicleForm', 'ext_color', data.ext_color));
  yield put(change('addVehicleForm', 'transmission', data.transmission));
  yield put(change('addVehicleForm', 'drive_train', data.drive_train));
  yield put(change('addVehicleForm', 'mileage', data.mileage));
  yield put(change('addVehicleForm', 'bluebookMileage', data.bluebook_mileage));
  yield put(change('addVehicleForm', 'bluebookValue', data.bluebook_value));
  yield put(change('addVehicleForm', 'bluebook_mileage_adjustment', data.bluebook_mileage_adjustment));
  yield put(change('addVehicleForm', 'bluebook_auction_value_good', data.bluebook_auction_value_good));
  yield put(
    change(
      'addVehicleForm',
      'options',
      estimateOptions.map(item => {
        if (data.options) {
          const tmpItem = data.options.find(svitem => svitem.bluebook_option_id === item.vehicleOptionId);
          if (data.options && tmpItem) {
            return { ...item, isTypical: tmpItem.is_typical };
          }
        }
        return item;
      })
    )
  );
  yield put(change('addVehicleForm', 'starting_bid', data.starting_bid));
  yield put(change('addVehicleForm', 'reserve_price', data.reserve_price?.toString()));
}

function* changeVehicleTrim({ payload }) {
  const trims = yield select(state => state.vehicles.vinInfoTrims);
  const selectedVehicle = yield select(state => state.vehicles.selectedVehicle.data);
  const trim = trims.find(item => item.trim === payload.trim) || {};
  if (trim && selectedVehicle && selectedVehicle.vin === trim.vin) {
    yield initDataAfterEdit(selectedVehicle, trim.options);
  } else if (trim && trim.options) {
    yield initInitialData(trim);
  }
}

function* requestVinInfo({ payload }) {
  try {
    const { vin: params } = payload;
    const vinInfo = yield select(state => state.vehicles.vinInfo);
    const isAdmin = Auth.getRole() === roles.ADMIN || getBackupToken();
    const { data } = yield call(Api.vehicles.getVinInfo, params.vin, {
      ...params,
      skipDuplicateCheck: isAdmin ? vinInfo.duplicate || payload.vehicleId : true
    });
    if (params.createSage) {
      if (data.prePopulate) {
        delete data.prePopulate.id;
        delete data.prePopulate.seller;
        delete data.prePopulate.auction_id;
        delete data.prePopulate.auction;
        delete data.prePopulate.images;
        delete data.prePopulate.status;
        delete data.prePopulate.user;
        yield put(vehicleDetails({ data: data.prePopulate }).success);
      } else if (params.vehicleId === '0') {
        yield put(vehicleDetails({ data: {} }).success);
      }
    }

    yield put(reset('addVehicleForm'));
    yield put(change('addVehicleForm', 'bluebookMileage', '$0'));
    yield put(change('addVehicleForm', 'bluebookValue', '$0'));
    payload.resolve();
    yield put(getVinInfo(data).success);
    const [firstTrim] = data.data;

    if (data.data.length === 1) {
      yield put(change('addVehicleForm', 'trim', firstTrim.trim));
    } else if (params.createSage) {
      const { year, make, model, images } = getForm();
      yield put(setVehicleImages(images));

      yield put(change('addVehicleForm', 'year', parseInt(year, 10) || firstTrim.year));
      yield put(change('addVehicleForm', 'make', make || firstTrim.make));
      yield put(change('addVehicleForm', 'model', model || firstTrim.model));
    }
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield call(payload.reject, new SubmissionError(validationErrors));
      yield put(getVinInfo(e).failure);
    } else {
      payload.reject(e);
      yield put(getVinInfo(e).failure);
    }
  }
}

function* requestCarEstimate({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.getCarEstimate, payload.data);

    const predictions = data.data;
    const [estimate] = predictions;

    const bluebook = estimate || {
      configuredValue: 0,
      mileageAdjustment: 0
    };

    yield put(change('addVehicleForm', 'bluebookValue', bluebook.configuredValue - bluebook.mileageAdjustment));
    yield put(change('addVehicleForm', 'bluebookMileage', bluebook.configuredValue));
    yield put(change('addVehicleForm', 'bluebook_mileage_adjustment', bluebook.mileageAdjustment));

    const goodConditionEstimate = predictions.find(item => item.condition && item.condition === 'Good');
    if (goodConditionEstimate) {
      yield put(change('addVehicleForm', 'bluebook_auction_value_good', goodConditionEstimate.configuredValue));
    }
    payload.resolve();
    yield put(getCarEstimate(data).success);
  } catch (e) {
    payload.reject(e);
    yield put(getCarEstimate(e).failure);
  }
}

const getAdditionalFormValues = state => ({
  images: state.vehicles.vehicleImages
});

const validateBeforeSubmit = (rules, values) =>
  rules.reduce((acc, { min, max, field, message, type }) => {
    const value = values[field];

    const validatitonViaRequiredField = type === VALIDATION_TYPES.REQUIRED;
    const isValidRequiredValidation = value !== undefined && value !== '';
    if (validatitonViaRequiredField && !isValidRequiredValidation) {
      return { ...acc, [field]: 'Required' };
    }

    const validationViaLength = type === VALIDATION_TYPES.LENGTH;
    const isValidLengthValidation = value && value.length <= max && value.length >= min;
    if (validationViaLength && !isValidLengthValidation) {
      return { ...acc, [field]: message || `Minimum length is ${min} and maximum is ${max}` };
    }

    if (field === 'images') {
      const isEmpty = value.some(image => !image.id) || !value.length;
      const isUploading = value.some(image => image.loading);
      const isFailed = value.some(image => image.failed);
      if (isFailed)
        return {
          ...acc,
          [field]: 'One of the images failed to upload. Try to upload them again or delete failed images'
        };
      if (isUploading) return { ...acc, [field]: message || 'Image still uploading' };
      if (isEmpty && type === VALIDATION_TYPES.CUSTOM) return { ...acc, [field]: message || 'Please upload image' };
      return acc;
    }

    if (field === 'options') {
      const optionsErrors = (values.options || []).reduce((errorsAcc, option) => {
        if (option.optionName === '' || option.optionName === undefined) {
          return [...errorsAcc, { optionName: 'Required' }];
        }
        return [...errorsAcc, null];
      }, []);
      const haveAnyError = optionsErrors.some(error => error !== null);
      return haveAnyError ? { ...acc, options: optionsErrors } : acc;
    }

    return acc;
  }, {});

function* uploadManualVehicle({ payload }) {
  try {
    const additionalInfo = yield select(getAdditionalFormValues);
    const userRole = yield select(userRoleSelector);
    const selectedOptions = [...payload.default_options, ...payload.options].filter(({ isTypical }) => isTypical);
    const values = {
      ...payload,
      ...additionalInfo,
      options: selectedOptions,
      mileage: payload.mileage || 0
    };
    let validationRules =
      payload.status === VEHICLE_STATUSES.ACTIVE ? vehicleSubmitRequiredFields : vehicleDraftRequiredFields;
    if (userRole === roles.SELLER) {
      validationRules = validationRules.filter(rule => rule.field !== 'seller_id');
    }

    if (values && values.seller_id) {
      if (values.seller_id.value) {
        values.seller_id = values.seller_id.value;
      }
    }

    const errors = validateBeforeSubmit(validationRules, values);
    const hasErrors = Object.keys(errors).length;
    if (hasErrors) {
      const [firstError] = Object.keys(errors);
      // Because images validation have custom errors
      const errorMessage = firstError === 'images' ? errors[firstError] : 'Validation Failed';
      yield put(stopSubmit('manualUploadForm', errors));
      yield put(snackShow({ message: errorMessage, type: 'error' }));
      scrollToError(errors, -300);
    } else if (values.id) {
      yield put(modalsToggle('loaderModal'));
      values.images = values.images.map(image => image.id);
      const { data } = yield call(Api.vehicles.updateVehicle, values.id, values);
      yield put(modalsToggle('loaderModal'));
      yield put(snackShow({ hideTime: 3000, message: 'Vehicle updated.' }));
      yield put(vehicleManualUpload(data.data).success);
    } else {
      yield put(modalsToggle('loaderModal'));
      values.images = values.images.map(image => image.id);
      const { data } = yield call(Api.vehicles.createManualVehicle, values);
      yield put(modalsToggle('loaderModal'));
      yield put(snackShow({ hideTime: 3000, message: 'Vehicle uploaded.' }));
      yield put(vehicleManualUpload(data.data).success);
    }

    yield put(salesApiSlice.util.invalidateTags(['Sales']));

    if (!hasErrors && values.status !== VEHICLE_STATUSES.DRAFT) {
      yield put(push(userRole === roles.ADMIN ? '/admin/vehicles' : '/seller/vehicles'));
    } else if (!hasErrors && values.status === VEHICLE_STATUSES.DRAFT) {
      const confirmNavigation = window.confirm('Navigate to the vehicles page?');
      if (confirmNavigation) {
        yield put(push(userRole === roles.ADMIN ? '/admin/vehicles' : '/seller/vehicles'));
      }
    }
  } catch (e) {
    if (e.response) {
      const errors = e.response.data.data;
      if (errors) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        yield put(stopSubmit('manualUploadForm', validationErrors));
        scrollToError(validationErrors, -300);
      }
      const message = errors && errors.length ? errors[0].message : e.response.data.message;
      yield put(snackShow({ message, type: 'error' }));
    } else if (e.message === 'Network Error') {
      yield put(
        snackShow({
          message: 'Vehicle upload failed because of network failure, please try again',
          type: 'error',
          hideTime: 3000
        })
      );
    }
    yield put(modalsToggle('loaderModal'));
    yield put(vehicleManualUpload(e).failure);
  }
}

function* initVehicleForm({ payload }) {
  try {
    if (payload) {
      yield put(modalsToggle('loaderModal'));
      const { data } = yield call(Api.vehicles.fetchVehicleDetails, payload);
      const { bids, auction, user, ...initValues } = data.data;
      const defaultOptions = VEHICLE_CREATION_DEFAULT_OPTIONS.reduce((acc, option) => {
        const defaultOptionWasSelected = initValues.options.find(({ option_name }) => option === option_name);
        const formattedOption = {
          optionName: option,
          vehicleOptionId: 1,
          isTypical: Boolean(defaultOptionWasSelected)
        };
        if (defaultOptionWasSelected) {
          initValues.options = initValues.options.filter(({ option_name }) => option !== option_name);
        }
        return [...acc, formattedOption];
      }, []);

      const auctionType = (() => {
        if (auction) return auction.type;
        return 'autoaxess';
      })();

      const vehicleFormData = {
        ...initValues,
        trim: initValues.trim || '',
        engine: initValues.engine || '',
        transmission: initValues.transmission || '',
        drive_train: initValues.drive_train || '',
        ext_color: initValues.ext_color || '',
        starting_bid: initValues.starting_bid || '',
        reserve_price: initValues.reserve_price || '',
        options: initValues.options.map(({ option_name, bluebook_option_id, is_typical }) => ({
          optionName: option_name,
          isTypical: is_typical,
          vehicleOptionId: bluebook_option_id
        })),
        default_options: defaultOptions,
        seller_id: data.data.user_id,
        auction_type: auctionType
      };
      yield put(initialize('manualUploadForm', vehicleFormData));
      const images = initValues.images.map(image => ({
        id: image.id,
        key: image.o,
        imageUrl: image.url
      }));
      yield put(setVehicleImages(images));
      yield put(modalsToggle('loaderModal'));
      yield put(vehicleFormInit(data).success);
    } else {
      yield put(setVehicleImages([]));
    }
  } catch (e) {
    console.warn(e);
    const errors = e.response.data.data;
    const message = errors && errors.length ? errors[0].message : e.response.data.message;
    yield put(snackShow({ message, type: 'error' }));
    yield put(modalsToggle('loaderModal'));
    yield put(vehicleFormInit(e).failure);
  }
}

function* vehicleImageUpload({ payload }) {
  try {
    yield put(snackShow({ message: 'Picture is being uploaded. Multiple images can be uploaded at the same time' }));
    const obj = {
      image: payload.file,
      index: payload.key
    };

    if (payload.vehicleId) {
      obj.vehicle_id = payload.vehicleId;
    }
    const { data } = yield call(Api.vehicles.uploadVehicleImage, obj);
    yield put(snackShow({ message: 'Image uploaded.' }));
    yield put(uploadVehicleImage(data.data).success);
  } catch (e) {
    if (e.response) {
      const errors = e.response.data.data;
      const message = errors && errors.length ? errors[0].message : e.response.data.message;
      yield put(snackShow({ message, type: 'error' }));
    } else if (e.message === 'Network Error') {
      yield put(
        snackShow({
          message: 'Image upload failed because of network request failure, please try upload this image again',
          type: 'error',
          hideTime: 3000
        })
      );
    }
    yield put(uploadVehicleImage(payload).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(CHANGE_VEHICLE_TRIM, changeVehicleTrim),
    takeLatest(GET_VIN_INFO_REQUEST, requestVinInfo),
    takeLatest(GET_CAR_ESTIMATE_REQUEST, requestCarEstimate),
    takeLatest(VEHICLE_MANUAL_UPLOAD_REQUEST, uploadManualVehicle),
    takeLatest(VEHICLE_FORM_INIT_REQUEST, initVehicleForm),
    takeLatest(UPDATE_VEHICLE_REQUEST, editVehicle),
    takeLatest(UPDATE_ADMIN_NOTES_REQUEST, editAdminNotes),
    takeLatest(CREATE_VEHICLE_REQUEST, createVehicle),
    takeEvery(UPLOAD_VEHICLE_IMAGE_REQUEST, vehicleImageUpload)
  ]);
}
