export const LIST_VEHICLES_REQUEST = 'LIST_VEHICLES_REQUEST';
export const LIST_VEHICLES_SUCCESS = 'LIST_VEHICLES_SUCCESS';
export const LIST_VEHICLES_FAILURE = 'LIST_VEHICLES_FAILURE';
export const LIST_VEHICLES_CLEAR = 'LIST_VEHICLES_CLEAR';

export const FILTER_SELLER_VEHICLES_FAILURE = 'FILTER_SELLER_VEHICLES_FAILURE';
export const FILTER_SELLER_VEHICLES_SUCCESS = 'FILTER_SELLER_VEHICLES_SUCCESS';
export const FILTER_SELLER_VEHICLES_REQUEST = 'FILTER_SELLER_VEHICLES_REQUEST';

export const LIST_VEHICLES_CHANGE_AUCTION_STATUS = 'LIST_VEHICLES_CHANGE_AUCTION_STATUS';

export const CREATE_VEHICLE_REQUEST = 'CREATE_VEHICLE_REQUEST';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_FAILURE = 'CREATE_VEHICLE_FAILURE';

export const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLE_FAILURE';

export const VEHICLES_SET_PAGE = 'VEHICLES_SET_PAGE';
export const VEHICLES_SET_PER_PAGE = 'VEHICLES_SET_PER_PAGE';
export const VEHICLES_SELLER_SET_PAGE = 'VEHICLES_SELLER_SET_PAGE';

export const GET_VIN_INFO_REQUEST = 'GET_VIN_INFO_REQUEST';
export const GET_VIN_INFO_SUCCESS = 'GET_VIN_INFO_SUCCESS';
export const GET_VIN_INFO_FAILURE = 'GET_VIN_INFO_FAILURE';
export const RESET_VIN_INFO = 'RESET_VIN_INFO';

export const GET_CAR_ESTIMATE_REQUEST = 'GET_CAR_ESTIMATE_REQUEST';
export const GET_CAR_ESTIMATE_SUCCESS = 'GET_CAR_ESTIMATE_SUCCESS';
export const GET_CAR_ESTIMATE_FAILURE = 'GET_CAR_ESTIMATE_FAILURE';
export const RESET_CAR_ESTIMATE = 'RESET_CAR_ESTIMATE';
export const CREATE_VEHICLE_SET_TMP_IMAGE = 'CREATE_VEHICLE_SET_TMP_IMAGE';
export const RESET_TMP_IMAGES = 'RESET_TMP_IMAGES';
export const VEHICLES_UNSET_SUCCESS = 'VEHICLES_UNSET_SUCCESS';
export const GET_VEHICLE_DETAILS_REQUEST = 'GET_VEHICLE_DETAILS_REQUEST';
export const GET_VEHICLE_DETAILS_SUCCESS = 'GET_VEHICLE_DETAILS_SUCCESS';
export const GET_VEHICLE_DETAILS_FAILURE = 'GET_VEHICLE_DETAILS_FAILURE';
export const RESET_VEHICLE_DETAILS = 'RESET_VEHICLE_DETAILS';
export const VEHICLE_AWARD_BUYER_REQUEST = 'VEHICLE_AWARD_BUYER_REQUEST';
export const VEHICLE_AWARD_BUYER_SUCCESS = 'VEHICLE_AWARD_BUYER_SUCCESS';
export const VEHICLE_AWARD_BUYER_FAILURE = 'VEHICLE_AWARD_BUYER_FAILURE';
export const VEHICLE_PULL_REQUEST = 'VEHICLE_PULL_REQUEST';
export const VEHICLE_PULL_SUCCESS = 'VEHICLE_PULL_SUCCESS';
export const VEHICLE_PULL_FAILURE = 'VEHICLE_PULL_FAILURE';
export const VEHICLE_RELIST_REQUEST = 'VEHICLE_RELIST_REQUEST';
export const VEHICLE_RELIST_SUCCESS = 'VEHICLE_RELIST_SUCCESS';
export const VEHICLE_RELIST_FAILURE = 'VEHICLE_RELIST_FAILURE';
export const VEHICLE_DEACTIVATE_REQUEST = 'VEHICLE_DEACTIVATE_REQUEST';
export const VEHICLE_DEACTIVATE_SUCCESS = 'VEHICLE_DEACTIVATE_SUCCESS';
export const VEHICLE_DEACTIVATE_FAILURE = 'VEHICLE_DEACTIVATE_SUCCESS';
export const VEHICLE_UNAWARD_REQUEST = 'VEHICLE_UNAWARD_REQUEST';
export const VEHICLE_UNAWARD_SUCCESS = 'VEHICLE_UNAWARD_SUCCESS';
export const VEHICLE_UNAWARD_FAILURE = 'VEHICLE_UNAWARD_FAILURE';
export const UPDATE_ADMIN_NOTES_REQUEST = 'UPDATE_ADMIN_NOTES_REQUEST';
export const VEHICLE_SELLER_ACCEPT_BID_REQUEST = 'VEHICLE_SELLER_ACCEPT_BID_REQUEST';
export const VEHICLE_SELLER_ACCEPT_BID_SUCCESS = 'VEHICLE_SELLER_ACCEPT_BID_SUCCESS';
export const VEHICLE_SELLER_ACCEPT_BID_FAILURE = 'VEHICLE_SELLER_ACCEPT_BID_FAILURE';
export const VEHICLE_ADMIN_APPROVE_BID_REQUEST = 'VEHICLE_ADMIN_APPROVE_BID_REQUEST';
export const VEHICLE_ADMIN_APPROVE_BID_SUCCESS = 'VEHICLE_ADMIN_APPROVE_BID_SUCCESS';
export const VEHICLE_ADMIN_APPROVE_BID_FAILURE = 'VEHICLE_ADMIN_APPROVE_BID_FAILURE';
export const VEHICLE_SELLER_SET_COUNTER_REQUEST = 'VEHICLE_SELLER_SET_COUNTER_REQUEST';
export const VEHICLE_SELLER_SET_COUNTER_SUCCESS = 'VEHICLE_SELLER_SET_COUNTER_SUCCESS';
export const VEHICLE_SELLER_SET_COUNTER_FAILURE = 'VEHICLE_SELLER_SET_COUNTER_FAILURE';
export const VEHICLE_ADMIN_SET_COUNTER_REQUEST = 'VEHICLE_ADMIN_SET_COUNTER_REQUEST';
export const VEHICLE_ADMIN_SET_COUNTER_SUCCESS = 'VEHICLE_ADMIN_SET_COUNTER_SUCCESS';
export const VEHICLE_ADMIN_SET_COUNTER_FAILURE = 'VEHICLE_ADMIN_SET_COUNTER_FAILURE';
export const VEHICLES_HOME_FILTERS_REQUEST = 'VEHICLES_HOME_FILTERS_REQUEST';
export const VEHICLES_HOME_FILTERS_SUCCESS = 'VEHICLES_HOME_FILTERS_SUCCESS';
export const VEHICLES_HOME_FILTERS_FAILURE = 'VEHICLES_HOME_FILTERS_FAILURE';

export const VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST = 'VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST';
export const VEHICLE_BUYER_ACCEPT_COUNTER_SUCCESS = 'VEHICLE_BUYER_ACCEPT_COUNTER_SUCCESS';
export const VEHICLE_BUYER_ACCEPT_COUNTER_FAILURE = 'VEHICLE_BUYER_ACCEPT_COUNTER_FAILURE';

export const LIST_LOCATIONS_REQUEST = 'LIST_LOCATIONS_REQUEST';
export const LIST_LOCATIONS_SUCCESS = 'LIST_LOCATIONS_SUCCESS';
export const LIST_LOCATIONS_FAILURE = 'LIST_LOCATIONS_FAILURE';
export const CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const LOCATIONS_SET_PAGE = 'LOCATIONS_SET_PAGE';
export const LOCATIONS_SET_PER_PAGE = 'LOCATIONS_SET_PER_PAGE';
export const LOCATIONS_SET_END_DAY = 'LOCATIONS_SET_END_DAY';
export const LOCATIONS_SET_LOCATION_NAME = 'LOCATIONS_SET_LOCATION_NAME';
export const LOCATIONS_UNSET_SUCCESS = 'LOCATIONS_UNSET_SUCCESS';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_EDIT_LOCATION = 'SET_EDIT_LOCATION';

export const LIST_REGIONS_REQUEST = 'LIST_REGIONS_REQUEST';
export const LIST_REGIONS_SUCCESS = 'LIST_REGIONS_SUCCESS';
export const LIST_REGIONS_FAILURE = 'LIST_REGIONS_FAILURE';

export const FILTER_AUCTIONS_REQUEST = 'FILTER_AUCTIONS_REQUEST';
export const FILTER_AUCTIONS_SUCCESS = 'FILTER_AUCTIONS_SUCCESS';
export const FILTER_AUCTIONS_FAILURE = 'FILTER_AUCTIONS_FAILURE';
export const LIST_AUCTIONS_REQUEST = 'LIST_AUCTIONS_REQUEST';
export const LIST_AUCTIONS_SUCCESS = 'LIST_AUCTIONS_SUCCESS';
export const LIST_AUCTIONS_FAILURE = 'LIST_AUCTIONS_FAILURE';
export const LIST_AUCTIONS_CLEAR = 'LIST_AUCTIONS_CLEAR';
export const CREATE_AUCTION_REQUEST = 'CREATE_AUCTION_REQUEST';
export const CREATE_AUCTION_SUCCESS = 'CREATE_AUCTION_SUCCESS';
export const CREATE_AUCTION_FAILURE = 'CREATE_AUCTION_FAILURE';
export const AUCTIONS_SET_PAGE = 'AUCTIONS_SET_PAGE';
export const AUCTIONS_SET_TYPE = 'AUCTIONS_SET_TYPE';
export const AUCTIONS_SET_PER_PAGE = 'AUCTIONS_SET_PER_PAGE';
export const AUCTIONS_SET_SEARCH_DATE = 'AUCTIONS_SET_SEARCH_DATE';
export const AUCTIONS_UNSET_SUCCESS = 'AUCTIONS_UNSET_SUCCESS';
export const AUCTIONS_GET_CURRENT_REQUEST = 'AUCTIONS_GET_CURRENT_REQUEST';
export const AUCTIONS_GET_CURRENT_SUCCESS = 'AUCTIONS_GET_CURRENT_SUCCESS';
export const AUCTIONS_GET_CURRENT_FAILURE = 'AUCTIONS_GET_CURRENT_FAILURE';
export const AUCTIONS_GET_NEXT_REQUEST = 'AUCTIONS_GET_NEXT_REQUEST';
export const AUCTIONS_GET_NEXT_SUCCESS = 'AUCTIONS_GET_NEXT_SUCCESS';
export const AUCTIONS_GET_NEXT_FAILURE = 'AUCTIONS_GET_NEXT_FAILURE';
export const AUCTIONS_GET_BY_ID_REQUEST = 'AUCTIONS_GET_BY_ID_REQUEST';
export const AUCTIONS_GET_BY_ID_SUCCESS = 'AUCTIONS_GET_BY_ID_SUCCESS';
export const AUCTIONS_GET_BY_ID_FAILURE = 'AUCTIONS_GET_BY_ID_FAILURE';
export const LIST_AUCTION_VEHICLES_REQUEST = 'LIST_AUCTION_VEHICLES_REQUEST';
export const LIST_AUCTION_VEHICLES_SUCCESS = 'LIST_AUCTION_VEHICLES_SUCCESS';
export const LIST_AUCTION_VEHICLES_FAILURE = 'LIST_AUCTION_VEHICLES_FAILURE';
export const AUCTION_EXPORT_SELLER_REPORT_REQUEST = 'AUCTION_EXPORT_SELLER_REPORT_REQUEST';
export const AUCTION_EXPORT_SELLER_REPORT_SUCCESS = 'AUCTION_EXPORT_SELLER_REPORT_SUCCESS';
export const AUCTION_EXPORT_SELLER_REPORT_FAILURE = 'AUCTION_EXPORT_SELLER_REPORT_FAILURE';

export const AUCTION_EXPORT_BUYER_REPORT_REQUEST = 'AUCTION_EXPORT_BUYER_REPORT_REQUEST';
export const AUCTION_EXPORT_BUYER_REPORT_SUCCESS = 'AUCTION_EXPORT_BUYER_REPORT_SUCCESS';
export const AUCTION_EXPORT_BUYER_REPORT_FAILURE = 'AUCTION_EXPORT_BUYER_REPORT_FAILURE';

export const FILTER_BIDS_REQUEST = 'FILTER_BIDS_REQUEST';
export const FILTER_BIDS_SUCCESS = 'FILTER_BIDS_SUCCESS';
export const FILTER_BIDS_FAILURE = 'FILTER_BIDS_FAILURE';

export const LIST_BIDS_REQUEST = 'LIST_BIDS_REQUEST';
export const LIST_BIDS_SUCCESS = 'LIST_BIDS_SUCCESS';
export const LIST_BIDS_FAILURE = 'LIST_BIDS_FAILURE';
export const LIST_BIDS_CHANGE_AUCTION_STATUS = 'LIST_BIDS_CHANGE_AUCTION_STATUS';
export const BIDS_SET_PAGE = 'BIDS_SET_PAGE';

export const SET_ACTIVE_BID = 'SET_ACTIVE_BID';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_FAILURE = 'LIST_USERS_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER_DOC_REQUEST = 'UPDATE_USER_DOC_REQUEST';
export const UPDATE_USER_DOC_SUCCESS = 'UPDATE_USER_DOC_SUCCESS';
export const UPDATE_USER_DOC_FAILURE = 'UPDATE_USER_DOC_FAILURE';
export const UPDATE_USER_DOC_FILE_REQUEST = 'UPDATE_USER_DOC_FILE_REQUEST';
export const UPDATE_USER_DOC_FILE_SUCCESS = 'UPDATE_USER_DOC_FILE_SUCCESS';
export const UPDATE_USER_DOC_FILE_FAILURE = 'UPDATE_USER_DOC_FILE_FAILURE';
export const DELETE_USER_DOC_REQUEST = 'DELETE_USER_DOC_REQUEST';
export const DELETE_USER_DOC_SUCCESS = 'DELETE_USER_DOC_SUCCESS';
export const DELETE_USER_DOC_FAILURE = 'DELETE_USER_DOC_FAILURE';

export const SNACK_SHOW = 'SNACK_SHOW';
export const SNACK_UNSET_MESSAGE = 'SNACK_UNSET_MESSAGE';

export const MODAL_TOGGLE = 'MODAL_TOGGLE';
export const MODAL_HIDE = 'MODAL_HIDE';

export const SUBSCRIBE_TO_WS = 'SUBSCRIBE_TO_WS';
export const UNSUBSCRIBE_FROM_WS = 'UNSUBSCRIBE_FROM_WS';

export const WS_VEHICLE_BIDS_NEW = 'WS_VEHICLE_BIDS_NEW';
export const WS_VEHICLE_DETAILS_UPDATE = 'WS_VEHICLE_DETAILS_UPDATE';
export const WS_VEHICLE_AUCTION_UPDATE = 'WS_VEHICLE_AUCTION_UPDATE';

export const WS_BIDS_NEW_BID = 'WS_BIDS_NEW_BID';
export const WS_BIDS_AUCTION_STATUS_CHANGE = 'WS_BIDS_AUCTION_STATUS_CHANGE';
export const WS_BIDS_VEHICLE_UPDATE = 'WS_BIDS_VEHICLE_UPDATE';
export const WS_BIDS_VEHICLE_DELETE = 'WS_BIDS_VEHICLE_DELETE';

export const WS_NEW_BID = 'WS_NEW_BID';
export const WS_NEW_PROXY = 'WS_NEW_PROXY';
export const WS_MY_BID_UPDATE = 'WS_MY_BID_UPDATE';

export const WS_NEW_VEHICLE = 'WS_NEW_VEHICLE';

export const WS_VEHICLE_NOTES_UPDATE = 'WS_VEHICLE_NOTES_UPDATE';
export const WS_VEHICLE_TIME_END = 'WS_VEHICLE_TIME_END';

export const WS_FIRST_BID = 'WS_FIRST_BID';

export const WS_OFFER_AUCTION_UPDATE = 'WS_OFFER_AUCTION_UPDATE';
export const WS_NEW_PRIVATE_OFFER = 'WS_NEW_PRIVATE_OFFER';

export const WS_NOTIFICATION = 'WS_NOTIFICATION';

export const WS_LR_CHAT_MESSAGE = 'WS_LR_CHAT_MESSAGE';

export const WS_LISTER_LOCATION_UPDATE = 'WS_LISTER_LOCATION_UPDATE';

export const LIST_SELLERS_INVOICES_REQUEST = 'LIST_SELLERS_INVOICES_REQUEST';
export const LIST_SELLERS_INVOICES_SUCCESS = 'LIST_SELLERS_INVOICES_SUCCESS';
export const LIST_SELLERS_INVOICES_FAILURE = 'LIST_SELLERS_INVOICES_FAILURE';
export const FILTER_SELLERS_INVOICES_REQUEST = 'FILTER_SELLERS_INVOICES_REQUEST';
export const FILTER_SELLERS_INVOICES_SUCCESS = 'FILTER_SELLERS_INVOICES_SUCCESS';
export const FILTER_SELLERS_INVOICES_FAILURE = 'FILTER_SELLERS_INVOICES_FAILURE';
export const LIST_SELLERS_INVOICES_SET_PAGE = 'LIST_SELLERS_INVOICES_SET_PAGE';
export const GET_DECLINED_TRANSACTIONS_SETPAGE = 'GET_DECLINED_TRANSACTIONS_SETPAGE';
export const LIST_SELLERS_INVOICES_SET_PER_PAGE = 'LIST_SELLERS_INVOICES_SET_PER_PAGE';
export const LIST_SELLERS_INVOICES_SET_SELLER = 'LIST_SELLERS_INVOICES_SET_SELLER';
export const LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST = 'LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST';
export const LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS = 'LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS';
export const LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE = 'LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE';
export const CREATE_SELLER_INVOICE_RECORD_REQUEST = 'CREATE_SELLER_INVOICE_RECORD_REQUEST';
export const CREATE_SELLER_INVOICE_RECORD_SUCCESS = 'CREATE_SELLER_INVOICE_RECORD_SUCCESS';
export const CREATE_SELLER_INVOICE_RECORD_FAILURE = 'CREATE_SELLER_INVOICE_RECORD_FAILURE';
export const SELLER_INVOICES_SET_DATE = 'SELLER_INVOICES_SET_DATE';
export const LIST_SELLER_PAST_INVOICES_REQUEST = 'LIST_SELLER_PAST_INVOICES_REQUEST';
export const LIST_SELLER_PAST_INVOICES_SUCCESS = 'LIST_SELLER_PAST_INVOICES_SUCCESS';
export const LIST_SELLER_PAST_INVOICES_FAILURE = 'LIST_SELLER_PAST_INVOICES_FAILURE';
export const LIST_CUSTOMERS_SUCCESS = 'LIST_CUSTOMERS_SUCCESS';
export const LIST_CUSTOMERS_FAILURE = 'LIST_CUSTOMERS_FAILURE';
export const LIST_CUSTOMERS_REQUEST = 'LIST_CUSTOMERS_REQUEST';
export const LIST_ACCOUNTS_SUCCESS = 'LIST_ACCOUNTS_SUCCESS';
export const LIST_ACCOUNTS_FAILURE = 'LIST_ACCOUNTS_FAILURE';
export const LIST_ACCOUNTS_REQUEST = 'LIST_ACCOUNTS_REQUEST';
export const LIST_ACCOUNT_LOGS_SUCCESS = 'LIST_ACCOUNT_LOGS_SUCCESS';
export const LIST_ACCOUNT_LOGS_FAILURE = 'LIST_ACCOUNT_LOGS_FAILURE';
export const LIST_ACCOUNT_LOGS_REQUEST = 'LIST_ACCOUNT_LOGS_REQUEST';
export const LIST_ADMIN_SELLER_SELLERS_SUCCESS = 'LIST_ADMIN_SELLER_SELLERS_SUCCESS';
export const LIST_ADMIN_SELLER_SELLERS_FAILURE = 'LIST_ADMIN_SELLER_SELLERS_FAILURE';
export const LIST_ADMIN_SELLER_SELLERS_REQUEST = 'LIST_ADMIN_SELLER_SELLERS_REQUEST';
export const LIST_REFERRERS_SUCCESS = 'LIST_REFERRERS_SUCCESS';
export const LIST_REFERRERS_FAILURE = 'LIST_REFERRERS_FAILURE';
export const LIST_REFERRERS_REQUEST = 'LIST_REFERRERS_REQUEST';
export const GENERATE_SELLER_INVOICE_FILE_REQUEST = 'GENERATE_SELLER_INVOICE_FILE_REQUEST';
export const GENERATE_SELLER_INVOICE_FILE_SUCCESS = 'GENERATE_SELLER_INVOICE_FILE_SUCCESS';
export const GENERATE_SELLER_INVOICE_FILE_FAILURE = 'GENERATE_SELLER_INVOICE_FILE_FAILURE';
export const LIST_CURRENT_SELLER_INVOICES_REQUEST = 'LIST_CURRENT_SELLER_INVOICES_REQUEST';
export const LIST_CURRENT_SELLER_INVOICES_SUCCESS = 'LIST_CURRENT_SELLER_INVOICES_SUCCESS';
export const LIST_CURRENT_SELLER_INVOICES_FAILURE = 'LIST_CURRENT_SELLER_INVOICES_FAILURE';
export const GET_DECLINED_TRANSACTIONS_REQUEST = 'GET_DECLINED_TRANSACTIONS_REQUEST';
export const GET_DECLINED_TRANSACTIONS_SUCCESS = 'GET_DECLINED_TRANSACTIONS_SUCCESS';
export const GET_DECLINED_TRANSACTIONS_FAILURE = 'GET_DECLINED_TRANSACTIONS_FAILURE';

export const DETAIL_CUSTOMER_REQUEST = 'DETAIL_CUSTOMER_REQUEST';
export const DETAIL_CUSTOMER_FAILURE = 'DETAIL_CUSTOMER_FAILURE';
export const DETAIL_CUSTOMER_SUCCESS = 'DETAIL_CUSTOMER_SUCCESS';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const FILTER_CUSTOMERS_REQUEST = 'FILTER_CUSTOMERS_REQUEST';
export const FILTER_CUSTOMERS_SUCCESS = 'FILTER_CUSTOMERS_SUCCESS';
export const FILTER_CUSTOMERS_FAILURE = 'FILTER_CUSTOMERS_FAILURE';

export const CUSTOMERS_SET_PAGE = 'CUSTOMERS_SET_PAGE';
export const ACCOUNTS_SET_PAGE = 'ACCOUNTS_SET_PAGE';
export const ACCOUNT_LOGS_SET_PAGE = 'ACCOUNT_LOGS_SET_PAGE';

export const DISABLE_BUYER_SUCCESS = 'DISABLE_BUYER_SUCCESS';
export const DISABLE_BUYER_FAILURE = 'DISABLE_BUYER_FAILURE';
export const DISABLE_BUYER_REQUEST = 'DISABLE_BUYER_REQUEST';

export const GET_AUCTION_SUMMARY_REPORT_REQUEST = 'GET_AUCTION_SUMMARY_REPORT_REQUEST';
export const GET_AUCTION_SUMMARY_REPORT_SUCCESS = 'GET_AUCTION_SUMMARY_REPORT_SUCCESS';
export const GET_AUCTION_SUMMARY_REPORT_FAILURE = 'GET_AUCTION_SUMMARY_REPORT_FAILURE';

export const GET_LISTER_SUMMARY_REPORT_REQUEST = 'GET_LISTER_SUMMARY_REPORT_REQUEST';
export const GET_LISTER_SUMMARY_REPORT_SUCCESS = 'GET_LISTER_SUMMARY_REPORT_SUCCESS';
export const GET_LISTER_SUMMARY_REPORT_FAILURE = 'GET_LISTER_SUMMARY_REPORT_FAILURE';

export const LIST_BUYER_FEES_REQUEST = 'LIST_BUYER_FEES_REQUEST';
export const LIST_BUYER_FEES_SUCCESS = 'LIST_BUYER_FEES_SUCCESS';
export const LIST_BUYER_FEES_FAILURE = 'LIST_BUYER_FEES_FAILURE';
export const UPDATE_BUYER_FEES_FAILURE = 'UPDATE_BUYER_FEES_FAILURE';
export const UPDATE_BUYER_FEES_REQUEST = 'UPDATE_BUYER_FEES_REQUEST';
export const UPDATE_BUYER_FEES_SUCCESS = 'UPDATE_BUYER_FEES_SUCCESS';

export const GET_BUYER_FEE_REQUEST = 'GET_BUYER_FEE_REQUEST';
export const GET_BUYER_FEE_SUCCESS = 'GET_BUYER_FEE_SUCCESS';
export const GET_BUYER_FEE_FAILURE = 'GET_BUYER_FEE_FAILURE';

export const RETRY_FEE_CHARGING_REQUEST = 'RETRY_FEE_CHARGING_REQUEST';
export const RETRY_FEE_CHARGING_SUCCESS = 'RETRY_FEE_CHARGING_SUCCESS';
export const RETRY_FEE_CHARGING_FAILURE = 'RETRY_FEE_CHARGING_FAILURE';

export const CHARGE_ADDITIONAL_FEES_REQUEST = 'CHARGE_ADDITIONAL_FEES_REQUEST';
export const CHARGE_ADDITIONAL_FEES_SUCCESS = 'CHARGE_ADDITIONAL_FEES_SUCCESS';
export const CHARGE_ADDITIONAL_FEES_FAILURE = 'CHARGE_ADDITIONAL_FEES_FAILURE';

export const SEND_CONTACT_FORM_REQUEST = 'SEND_CONTACT_FORM_REQUEST';
export const SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS';
export const SEND_CONTACT_FORM_FAILURE = 'SEND_CONTACT_FORM_FAILURE';

export const SEND_QUESTION_FORM_REQUEST = 'SEND_QUESTION_FORM_REQUEST';
export const SEND_QUESTION_FORM_SUCCESS = 'SEND_QUESTION_FORM_SUCCESS';
export const SEND_QUESTION_FORM_FAILURE = 'SEND_QUESTION_FORM_FAILURE';

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE';

export const TOTALS_INVOICES_REQUEST = 'TOTALS_INVOICES_REQUEST';
export const TOTALS_INVOICES_SUCCESS = 'TOTALS_INVOICES_SUCCESS';
export const TOTALS_INVOICES_FAILURE = 'TOTALS_INVOICES_FAILURE';

export const REQUEST_IN_PROGRESS = 'REQUEST_IN_PROGRESS';

export const FINALIZE_SALE_SET_MODAL = 'FINALIZE_SALE_SET_MODAL';
