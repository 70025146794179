import { useBuyerGlobalSocketHandler } from 'services/socket/handleBuyerEvents';
import { useMyBidsSocketEffect } from 'services/socket/handleMyBidsEvent';
import { useMySalesSocketEffect } from '../../services/socket/handleSaleEvents';

export default function GlobalSocketProvider() {
  useBuyerGlobalSocketHandler();
  useMyBidsSocketEffect();
  useMySalesSocketEffect();

  return null;
}
