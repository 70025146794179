import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { myBidsApiSlice as api } from 'services/api/my-bids';
import { SocketMessage } from 'services/api/types';
import getSocket from '.';
import { useEffect } from 'react';
import { WS_FIRST_BID } from 'constants/actionTypes';

export const useBuyerGlobalSocketHandler = () => {
  const user = useSelector((state: any) => state.user?.user);
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>;

  const listener = ({ type }: SocketMessage) => {
    if (type === WS_FIRST_BID) {
      dispatch(api.util.invalidateTags(['MyBids', 'MyBidsCounters']));
    }
  };

  useEffect(() => {
    if (!user || user.role !== 'buyer') return;

    const socket = getSocket();

    socket.emit('subscribe', {
      prefix: `user`,
      ids: [user.id]
    });
    socket.on('message', listener);

    return () => {
      socket.emit('unsubscribe', {
        prefix: `user`,
        ids: [user.id]
      });

      socket.off('message', listener);
    };
  }, [dispatch, user.id, user.role]);
};
