import { BlockProps } from './types';
import { IncreaseHighBid } from '../../containers';
import { BID_INCREMENT, BUYER_VEHICLE_STATUS } from '../../../../../constants';
import { useSelector } from 'react-redux';
import { useOfferAuctionBidsInfo } from '../../../../../hooks/useOfferAuctionBidsInfo';
import { OfferAuction, OfferAuctionStatus } from '../../../../../types/vehicle';
import { useBidsOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useVehicleBidsActions } from '../hooks/useVehicleBidsActions';
import { getMinBitAmount } from '../../../../../utils/vehicle';

type Props = BlockProps & {
  offerAuction?: OfferAuction | null;
  status: string;
  isMobileView?: boolean;
  withButtons?: boolean;
};

export const RenderPlaceBid = ({
  currentVehicle,
  offerAuction,
  status,
  isMobileView = false,
  withButtons = false
}: Props) => {
  const user = useSelector((state: any) => state.user?.user);

  const { minBidOfferAmount } = useOfferAuctionBidsInfo(offerAuction);

  const minBidAmount = getMinBitAmount(currentVehicle, user)

  const canPlaceBid = [
    BUYER_VEHICLE_STATUS.OUT_BID,
    BUYER_VEHICLE_STATUS.HIGH_BID,
    BUYER_VEHICLE_STATUS.ACTIVE
  ].includes(status);
  const canPlaceOffer =
    [BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER, BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS].includes(
      status
    ) && !!offerAuction;

  const variant = isMobileView ? 'mobile-panel' : 'default';

  const buttons = useVehicleBidsActions(currentVehicle, offerAuction, status);

  return (
    <>
      {canPlaceBid ? (
        <IncreaseHighBid
          form={`biddingForm_${currentVehicle.id}`}
          vehicleId={currentVehicle.id}
          minAmount={minBidAmount}
          bidIncrement={BID_INCREMENT}
          variant={variant}
          vehicle={currentVehicle}
          buttons={buttons}
          withButtons={withButtons}
        />
      ) : null}
      {canPlaceOffer ? (
        <IncreaseHighBid
          form={`offerBiddingForm_${currentVehicle.id}`}
          vehicleId={currentVehicle.id}
          minAmount={minBidOfferAmount}
          vehicle={currentVehicle}
          bidIncrement={BID_INCREMENT}
          type="offer"
          variant={variant}
          isBackupOffer={offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS}
          buttons={buttons}
          withButtons={withButtons}
        />
      ) : null}
    </>
  );
};
