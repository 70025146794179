import { VEHICLE_STATUSES, BID_INCREMENT } from '../constants';
import moment from 'moment';

export const getHighestBid = vehicle => {
  // const awardedBid =
  //   vehicle.status === VEHICLE_STATUSES.AWARDED &&
  //   (vehicle.bids ?? []).reduce((acc, bid) => (bid.id > acc.id ? bid : acc), {
  //     amount: 0,
  //     id: 0
  //   });

  // const highestBid = (vehicle.bids ?? []).reduce(
  //   (acc, bid) => {
  //     if (bid.amount && bid.amount >= acc.amount && bid.id > acc.id) {
  //       return bid;
  //     }
  //     return acc;
  //   },
  //   { amount: 0, id: 0, bidder: {} }
  // );

  return vehicle.highest_bid ?? { amount: 0, id: 0, bidder: {} };
};

export const getUserHighestBid = (vehicle, userId) => {
  if (userId && userId === vehicle.proxy_bid?.user_id) {
    return { amount: vehicle.proxy_bid.amount, isProxy: true };
  }

  return { amount: getHighestBid(vehicle)?.amount, isProxy: false };
};

export const getVehicleTimer = vehicle => {
  const endStatuses = [VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.PULLED];
  if (endStatuses.includes(vehicle.status)) {
    return 0;
  }

  const isShuffled = vehicle.date_end != null;
  return isShuffled ? moment(vehicle.date_end) : moment(vehicle.auction.date_end);
};

export const getMinBitAmount = (vehicle, user) => {
  const startingBid = Number(vehicle.starting_bid || 100);

  // no bids -> return starting bid
  if (!vehicle.highest_bid) {
    return startingBid;
  }

  // proxy belongs to curr user -> increase proxy by bid_increment
  if (user?.id && user.id === vehicle.proxy_bid?.user_id) {
    return Math.max(Number(vehicle.proxy_bid.amount) + BID_INCREMENT, startingBid);
  }

  return Math.max(Number(vehicle.highest_bid.amount ?? 0) + BID_INCREMENT, startingBid);
};
