import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount, SocketMessage } from '../types';
import {
  WS_MY_BID_UPDATE,
  WS_NEW_BID,
  WS_NEW_PROXY,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_VEHICLE_NOTES_UPDATE,
  WS_VEHICLE_TIME_END
} from '../../../constants/actionTypes';
import getSocket from '../../socket';
import { VehicleSocketEventHandlerManager } from '../socketHelpers';
import moment from 'moment';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';

type MyBidsQueryArgs = {
  [x: string]: any;
};

export const bidsAdapter = createEntityAdapter<Vehicle>({
  // sortComparer: (a, b) => moment(a.date_end).diff(b.date_end)
});

export const sidePanelBidsAdapter = createEntityAdapter<Vehicle>({
  // sortComparer: (a, b) => moment(a.date_end).diff(b.date_end)
});

export const myBidsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMyBids: builder.query<EntityWithCount<Vehicle>, MyBidsQueryArgs>({
      query: ({ limit, offset, ...filters }) => ({
        url: `/buyer/bids`,
        method: 'GET',
        params: {
          limit,
          offset,
          ...filters
        }
      }),
      providesTags: (_res, _error, arg) => (arg.limit === 999 ? ['MobileMyBids'] : ['MyBids']),
      transformResponse: (response: any) => {
        return {
          rows: bidsAdapter.addMany(bidsAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg, baseQueryMeta }) => {
        const newVehicles = (newItems.rows?.ids?.map(el => newItems.rows.entities[el]) ?? []) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          bidsAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          bidsAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    getMyBidsCounters: builder.query<{ active: number; pending: number }, void>({
      query: () => ({
        url: 'buyer/bids-counters',
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ['MyBidsCounters']
    }),
    getSidePanelBids: builder.query<EntityWithCount<Vehicle>, void>({
      query: () => ({
        url: `/buyer/bids`,
        method: 'GET',
        params: {
          limit: 999,
          offset: 1
        }
      }),
      providesTags: ['SidePanelBids'],
      transformResponse: (response: any) => {
        return {
          rows: bidsAdapter.addMany(bidsAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState, dispatch }) {
        const store = getState() as any;
        const userId = store.user?.user?.id;
        if (!userId) return;
        const socket = getSocket();

        const listener = ({ payload, type }: SocketMessage) => {
          const socketManager = new VehicleSocketEventHandlerManager(updateCachedData, sidePanelBidsAdapter, {
            payload,
            type
          });
          socketManager.registerHandlers([
            WS_VEHICLE_NOTES_UPDATE,
            WS_NEW_PROXY,
            WS_VEHICLE_DETAILS_UPDATE,
            WS_NEW_BID,
            WS_VEHICLE_TIME_END,
            WS_MY_BID_UPDATE
          ]);
        };
        try {
          const data = await cacheDataLoaded;
          for (const vehicleId of data.data.rows.ids) {
            socket.emit('subscribe', `vehicles:${vehicleId}`);
            socket.emit('subscribe', `vehicles-notes:${userId}:${vehicleId}`);
          }
          socket.on('message', listener);
        } catch {}
        await cacheEntryRemoved;
        socket.off('message', listener);
      }
    }),
    placeBid: builder.mutation<any, any>({
      query: body => ({
        url: '/bids',
        method: 'POST',
        body
      }),
      invalidatesTags: ['SidePanelBids', 'MobileMyBids']
    }),
    placeSidePanelBid: builder.mutation<any, any>({
      query: body => ({
        url: '/bids',
        method: 'POST',
        body
      }),
      invalidatesTags: ['MyBids', 'MyBidsCounters', 'SidePanelBids']
    }),
    placeAdminBid: builder.mutation<void, { vehicle_id: number; buyer_id: number; amount: number }>({
      query: body => ({
        url: 'admin/bids',
        method: 'POST',
        body
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetMyBidsQuery,
  useGetMyBidsCountersQuery,
  useGetSidePanelBidsQuery,
  usePlaceBidMutation,
  usePlaceSidePanelBidMutation,
  usePlaceAdminBidMutation
} = myBidsApiSlice;

export const useMyBidsQueryState = myBidsApiSlice.endpoints.getMyBids.useQueryState;
