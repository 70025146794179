import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Table, VehiclePopover, HighestBidWithBuyer } from '../../../../components';
import { BidStatus, Seller } from './index';
import { AUCTION_STATUSES } from '../../../../constants';
import { AdminVehicleImage } from './AdminVehicleImage';
import { AdminReservePrice } from './AdminReservePrice';
import { AdminOffers } from './AdminOffers';
import { AdminProxyOffers } from './AdminProxyOffer';
import { AdminActions } from './AdminActions';
import { useGetAuctionVehiclesQuery } from 'services/api/admin-auction-vehicles';
import { AdminCounterAmount } from './AdminCounterAmount';
import { SendOfferModal } from '../../../../components/Offers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { setPage } from '../../../../services/auctionVehicles/auctionVehiclesSlice';
import { AcceptTopOfferModal } from './AcceptTopOfferModal';
import { AdminAcceptHighBidModal } from '../components/AdminAcceptHighestBidModel';
import { AdminLabelComponent } from './AdminLabel';
import { AdminOfferLogButton } from './AdminOfferLogButton';
import { useAdminVehiclesSocketEffect } from 'services/socket/handleAdminEvents';
import { PullVehicleModal } from './AdminPullModal';
import { RelistDialog } from '../../../../components/RelistDialog/RelistDialog';
import { ExtendVehicleModal } from './AdminExtendTimeModal';
import { AdminPlaceOfferButton } from './AdminPlaceOfferButton';
import { OtherOffers } from './AdminOtherOffers';
import { AdminProxyBid } from './AdminProxyBid';
import { AdminPlaceBidButton } from './AdminPlaceBidButton';
import { ToggleLockButton } from './ToggleLockButton';

const ActionHeaderComponentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

const ActionsHeaderComponent = () => (
  <ActionHeaderComponentWrapper>
    <span>Actions</span>
    <AdminOfferLogButton />
    <AdminPlaceOfferButton />
  </ActionHeaderComponentWrapper>
);

const LockActionComponent = () => {
  const user = useSelector(state => state.user.user);
  if (!['Admin', 'Manager'].includes(user.job_title)) {
    return null;
  }

  return <ToggleLockButton />;
};

const StyledTableWrapper = styled.div`
  tr {
    td:last-child button {
      min-width: 100px;
    }

    td:last-child {
      padding: 12px !important;
      padding-left: 0 !important;
    }

    td:nth-child(3) {
      max-width: 100px;
      min-width: 100px;
    }
    td:nth-child(5) {
      padding-right: 0px;
      max-width: 100px;
      min-width: 100px;
    }
    td:nth-child(6),
    td:nth-child(7) {
      padding-right: 4px;
    }
    td:nth-child(10) {
      padding: 0;
    }
    td:nth-child(11) {
      max-width: 0;
      min-width: 0;
      padding: 0;
      display: none;
    }
  }

  thead > tr {
    th:nth-child(5) {
      min-width: 80px;
    }
    th:nth-child(11) {
      display: none;
    }
  }
`;

const serializeVehiclesForTable = (vehicles, admin) => {
  if (!vehicles) return [];
  return vehicles.map(vehicle => {
    let highBid = vehicle.highest_bid;
    let proxyBid = null;

    if (vehicle.proxy_bid) {
      proxyBid = vehicle.proxy_bid.amount;
    }
    if (highBid && proxyBid < highBid.amount) {
      proxyBid = highBid.amount;
    }

    return {
      ...vehicle,
      admin_modals: (
        <div>
          <SendOfferModal
            maxAmount={vehicle.reserve_price}
            form={`sendVehicleOffer-${vehicle.id}`}
            vehicle={vehicle}
            isAdminOffer
          />
          <AcceptTopOfferModal vehicle={vehicle} />
          <AdminAcceptHighBidModal vehicle={vehicle} />
          <PullVehicleModal vehicle={vehicle} />,
          <ExtendVehicleModal vehicle={vehicle} />
          <RelistDialog vehicle={vehicle} />
        </div>
      ),
      image: <AdminVehicleImage vehicle={vehicle} />,
      vehicle_detail: <VehiclePopover showNotes showLister showRelistHistory vehicleData={vehicle} />,
      seller: (
        <Seller
          showHoverInfo
          userId={vehicle.user.id}
          name={vehicle.user.dealership_name}
          seller={vehicle.user.seller}
          loginOnClick={admin?.job_title !== 'Lister'}
          adminNavPage={`/admin/auctions/${vehicle.auction_id}`}
        />
      ),
      // seller: vehicle.user.dealership_name,
      ending_bid: <HighestBidWithBuyer vehicle={vehicle} showHoverInfo={!vehicle.highest_bid?.bidder?.is_bot} />,
      other_offers: <OtherOffers vehicle={vehicle} />,
      reserve_price: <AdminReservePrice vehicle={vehicle} />,
      bid_status: <BidStatus vehicle={vehicle} />,
      offers: <AdminOffers vehicle={vehicle} />,
      proxy_offer: <AdminProxyOffers vehicle={vehicle} />,
      proxy: <AdminProxyBid vehicle={vehicle} />,
      counter_amount: <AdminCounterAmount vehicle={vehicle} />,
      actions: <AdminActions vehicle={vehicle} />,
      timer: vehicle.auction.status === AUCTION_STATUSES.ENDED ? 'Ended' : 'Active'
    };
  });
};

const AuctionDetailTableFC = () => {
  const dispatch = useDispatch();
  const admin = useSelector(state => state.user?.user);

  const { id: auctionId } = useParams();

  const { page, perPage, filters } = useSelector(state => state.auctionVehicles);
  const { data, isFetching: loading } = useGetAuctionVehiclesQuery({
    limit: perPage,
    offset: page,
    auction_id: auctionId,
    ...filters
  });

  const vehicles = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const vehiclesCount = useMemo(() => data?.count ?? 0, [data]);

  const loadMore = () => {
    if (page * perPage > vehiclesCount) return;
    dispatch(setPage(page + 1));
  };

  const getRowBackgroundColor = item => {
    if (item.status === 'pulled') {
      return '#B0B0B04D';
    }

    return '#fff';
  };

  useEffect(() => {
    return () => dispatch(setPage(1));
  }, [dispatch]);

  useAdminVehiclesSocketEffect(data);

  const icons = [AdminLabelComponent];

  return (
    <StyledTableWrapper>
      <Table
        icons={icons}
        columnsHead={[
          { title: '', key: 'image', component: <LockActionComponent /> },
          { title: 'Car Details', key: 'vehicle_detail' },
          { title: 'High Bid', key: 'ending_bid' },
          { title: 'Proxy', key: 'proxy' },
          // { title: 'Reserve Price', key: 'reserve_price' },
          { title: 'Other Offers', key: 'other_offers' },
          { title: 'Wants', key: 'counter_amount' },
          { title: 'Seller', key: 'seller' },
          { title: 'Offers', key: 'offers' },
          { title: 'Proxy Offer', key: 'proxy_offer' },
          { title: 'Bid State', key: 'bid_status' },
          { title: '', key: 'admin_modals' },
          { title: 'Actions', key: 'actions', component: <ActionsHeaderComponent /> }
        ]}
        getRowBackgroundColor={getRowBackgroundColor}
        columnsData={serializeVehiclesForTable(vehicles, admin)}
        rowsCount={vehiclesCount}
        buttons={undefined}
        page={page}
        rowsPerPage={perPage}
        loadMore={loadMore}
        loading={loading}
        noDataText={
          <>
            No vehicles found
            <br />
            Add a vehicle or change filter
          </>
        }
      />
    </StyledTableWrapper>
  );
};

export default AuctionDetailTableFC;
