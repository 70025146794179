import NumberFormat from 'react-number-format';
import { getHighestBid } from '../../utils/vehicle';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import { useRef, useState } from 'react';
import { useIsTextOverflown } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsUser } from '../../actions';
import { HoverInfoTooltip } from '../../pages/Admin/AuctionDetail/components/HoverInfo';
import { useCustomerHoverStateHandlers } from '../../pages/Admin/AuctionDetail/hooks';
import { convertToLocalTime } from '../../utils';
import { ButtonBase, Icon, Popover } from '@material-ui/core';
import { ViewAllBidsTooltip } from '../../pages/Shared/VehicleDetails/components/BidHistorySection/components/ViewAllBidsModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 14px;
`;

const BidderName = styled.span`
  color: #757575;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 66px;
  cursor: pointer;
`;

const Label = styled.span`
  color: #757575;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  max-width: 66px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 8px;
`;

const StyledBackButton = styled(ButtonBase)`
  margin-top: -4px;
`;

const companyToLabel: Record<string, string> = {
  acv: 'ACV',
  other: 'Other',
  open_lane: 'OL'
};

export const HighestBidWithBuyer = ({ vehicle, showHoverInfo = false }: any) => {
  const highestBid = getHighestBid(vehicle);
  const buyerName = (() => {
    if (highestBid.bidder && highestBid.bidder.is_bot) {
      return `${companyToLabel[highestBid.bidder.company_name]} - ${highestBid.bidder.dealership_name}`;
    }
    return highestBid.bidder?.dealership_name;
  })();
  const admin = useSelector((state: any) => state?.user?.user);
  const isLister = admin?.job_title === 'Lister';

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const vehicleBids = (vehicle.bids || []).map((bid: any) => ({
    id: bid.id,
    bid: bid.amount,
    bidder: bid.bidder?.dealership_name,
    date: convertToLocalTime(bid.created_at, true),
    created_at: bid.created_at,
    admin: bid.admin
  }));

  const dispatch = useDispatch();

  const handleClick = () => {
    if (highestBid.bidder?.is_bot || isLister) return;
    dispatch(loginAsUser({ id: highestBid.user_id, initialPage: `/admin/auctions/${vehicle.auction_id}` }).request);
  };

  return (
    <Wrapper>
      <span style={{ fontWeight: 500 }}>
        <NumberFormat displayType="text" prefix="$" value={(highestBid && highestBid.amount) || 0} thousandSeparator />
      </span>
      {buyerName && showHoverInfo && highestBid.bidder && (
        <RenderBuyerNameWithHoverInfo
          userId={highestBid.bidder.id}
          initialPage={`/admin/auctions/${vehicle.auction_id}`}
          buyerName={buyerName}
        />
      )}
      {buyerName && !showHoverInfo && (
        <Tooltip isTextOverflown={isTextOverflown} title={buyerName} arrow light>
          <BidderName ref={ref} onClick={handleClick}>
            {buyerName}
          </BidderName>
        </Tooltip>
      )}
      {vehicleBids.length > 0 && (
        <>
          <StyledBackButton onClick={handleOpenPopover}>
            <Icon>expand_more</Icon>
          </StyledBackButton>
          <Popover
            id="bid-history-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            marginThreshold={150}
          >
            <ViewAllBidsTooltip
              bids={vehicleBids}
              vehicleId={vehicle.id}
              type={'bids'}
              proxyBid={vehicle.proxy_bid}
              form={`adminEditBidModalForm-bids`}
            />
          </Popover>
        </>
      )}
    </Wrapper>
  );
};

const RenderBuyerNameWithHoverInfo = ({
  userId,
  buyerName,
  initialPage
}: {
  userId: number;
  buyerName: string;
  initialPage: string;
}) => {
  const { isOpen, handleClose, handleOpen } = useCustomerHoverStateHandlers();

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  if (!buyerName) return null;

  return (
    <HoverInfoTooltip
      anchorEl={ref.current}
      initialPage={initialPage}
      userId={userId}
      isPopoverOpened={isOpen}
      closeTooltip={handleClose}
      showTooltip={isTextOverflown}
      tooltipName={buyerName}
    >
      <BidderName ref={ref} onClick={handleOpen}>
        {buyerName}
      </BidderName>
    </HoverInfoTooltip>
  );
};
