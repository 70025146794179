import React, { Component } from 'react';
import {
  Table as MaterialTable,
  Typography,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './index.scss';
import { TableButton } from './common';
import { MOBILE_BREAKPOINT } from '../../constants';
import { TabletBottomBlock } from '../../pages/Buyer/BidsList/components/tableBlocks/TabletBottomBlock';

const StyledTableWrapper = styled.div`
  overflow: auto;
`;

const SoloTableRaw = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  background: ${props => props.background};
  width: calc(100vw - 28px);
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
`;

const MobileButton = styled.div`
  & > button {
    width: 100%;
  }
`;

const StyledTextWhenNoData = styled(Typography)`
  font-size: 18px;
  line-height: 2;
  margin: 65px 25px 25px 25px;
  text-align: center;
`;

const PaperRow = ({ rowElement, ...props }) => {
  return <Paper component={rowElement} {...props} />;
};

const styles = {
  tableRoot: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px'
  },
  cellRoot: {
    padding: '8px'
  },
  footerCell: {
    textAlign: 'center'
  },
  tableHead: {
    border: 0,
    padding: '8px'
  }
};

// TODO: adjust bottom position by index
const RowFooter = styled.div`
  position: absolute;
  width: calc(100vw - 24px);
  height: ${props => {
    if (props.size === 'lg') return '120px';
    if (props.size === 'sm') return '40px';
    return '60px';
  }};
  bottom: ${props => {
    if (props.size === 'lg') return '-240px';
    if (props.size === 'sm') return '-180px';
    return '-180px';
  }};
  left: calc(-100vw + 24px);
`;

const StyledTableRow = styled(TableRow)`
  position: relative !important;
  background: ${props => props.background};
`;

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;

    if (prevProps.loading !== loading) {
      this.loadMore();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onScroll = () => {
    const { loading, rowsCount, columnsData } = this.props;
    if (!loading && columnsData.length < rowsCount) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { loading, rowsCount, columnsData } = this.props;
    const el = document.getElementById('load-trigger');
    if (el) {
      const bounding = el.getBoundingClientRect();
      const isInViewPort = bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      console.log(isInViewPort, 'isInViewPort');
      if (isInViewPort) {
        if (!loading && columnsData.length < rowsCount) {
          const { loadMore } = this.props;
          loadMore();
        }
      }
    }
  };

  renderColumnsHead = () => {
    const { columnsHead, classes } = this.props;
    return columnsHead.map((item, i) => (
      <TableCell key={i} classes={{ root: classes.tableHead }} className={item.cellClass}>
        {item.component ? item.component : item.title}
      </TableCell>
    ));
  };

  renderColumnsData = () => {
    const {
      columnsData,
      columnsHead,
      buttons,
      classes,
      renderTextOnHover,
      withMobileView,
      icons,
      tableRowClick,
      footerColumns = [],
      tableFooterClick,
      tableCellClick,
      rowElement = 'tr',
      getRowBackgroundColor = () => '#fff'
    } = this.props;
    const { width: screenWidth } = this.state;
    if (screenWidth <= MOBILE_BREAKPOINT && withMobileView) {
      return columnsData.map((item, i) => {
        const backgroundColor = getRowBackgroundColor(item);
        return (
          <TableRow style={{ width: '100%' }}>
            <SoloTableRaw background={backgroundColor}>
              {icons && icons.map(Icon => <Icon id={item.id} item={item} key={item.id} />)}
              {columnsHead.map((head, j) => item[head.key] && item[head.key])}
              {buttons && this.renderButtons(item)}
            </SoloTableRaw>
          </TableRow>
        );
      });
    }

    return columnsData.map((item, i) => {
      const backgroundColor = getRowBackgroundColor(item);
      return (
        <StyledTableRow
          background={backgroundColor}
          title={renderTextOnHover ? renderTextOnHover(item) : ''}
          component={PaperRow}
          rowElement={rowElement}
          key={item.key || i}
          onClick={e => {
            if (tableRowClick) {
              tableRowClick(e, item, i);
            }
          }}
        >
          {columnsHead.map(
            (head, j) =>
              item[head.key] && (
                <TableCell
                  onClick={e => {
                    if (tableCellClick) {
                      tableCellClick(e, item, j);
                    }
                  }}
                  classes={{ root: classes.cellRoot }}
                  className={head.cellClass}
                  key={j}
                >
                  {item[head.key]}
                </TableCell>
              )
          )}
          {icons && icons.map(Icon => <Icon id={item.id} key={item.id} item={item} />)}
          {buttons && this.renderButtons(item)}
          {footerColumns.map(el => (
            <div key={el.key} style={{ position: 'relative' }}>
              <RowFooter onClick={tableFooterClick} size={el.size}>
                {item[el.key]}
              </RowFooter>
            </div>
          ))}
        </StyledTableRow>
      );
    });
  };

  renderButtons = itemData => {
    const { buttons, withMobileView, buttonPosition = 'right' } = this.props;
    const { width: screenWidth } = this.state;
    const positionClass = buttonPosition === 'center' ? 'text-center' : 'text-right';
    const WrapperComponent =
      screenWidth <= MOBILE_BREAKPOINT && withMobileView
        ? ({ children }) => <MobileButton>{children}</MobileButton>
        : ({ children }) => <TableCell className={`${positionClass} table-buttons`}>{children}</TableCell>;
    if (!buttons || buttons.length === 0) return null;
    return (
      <WrapperComponent>
        {buttons.map((btn, k) => (
          <TableButton btn={btn} key={`b${k}`} itemData={itemData} />
        ))}
      </WrapperComponent>
    );
  };

  renderHead = () => (
    <TableHead>
      <TableRow>{this.renderColumnsHead()}</TableRow>
    </TableHead>
  );

  renderBody = () => <TableBody>{this.renderColumnsData()}</TableBody>;

  renderTextWhenNoData = () => {
    const { noDataText } = this.props;
    return <StyledTextWhenNoData>{noDataText}</StyledTextWhenNoData>;
  };

  render() {
    const { loading, pagination, columnsHead, classes, renderTextWhenNoData, columnsData, withMobileView } = this.props;
    const { width: screenWidth } = this.state;

    const isMobile = withMobileView && screenWidth <= MOBILE_BREAKPOINT;

    if (renderTextWhenNoData && !(columnsData || []).length) {
      return this.renderTextWhenNoData();
    }

    return (
      <StyledTableWrapper>
        <MaterialTable classes={{ root: classes.tableRoot }}>
          {!isMobile && this.renderHead()}
          {this.renderBody()}
          {pagination && (
            <TableFooter>
              <TableRow>
                <TableCell classes={{ root: classes.footerCell }} colSpan={columnsHead.length} id="load-trigger">
                  {loading ? <CircularProgress /> : null}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </MaterialTable>
      </StyledTableWrapper>
    );
  }
}

Table.propTypes = {
  columnsHead: PropTypes.array.isRequired,
  columnsData: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
  buttons: PropTypes.array,
  rowsCount: PropTypes.number.isRequired,
  loadMore: PropTypes.func,
  loading: PropTypes.bool
};

Table.defaultProps = {
  pagination: true,
  buttons: [],
  loadMore: () => {},
  renderTextWhenNoData: true,
  noDataText: 'No data found'
};

export default withStyles(styles)(Table);
