// import { reduxForm, Field } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { ModalWrapper } from '../../../../components';
import { FormFormattedField, FormInput, FormTextAreaField } from '../../../../components/Form';
import { maxLength, validateMinBid, validateReservePrice } from '../../../../utils/validators';
import { updateVehicle } from '../../../../actions';
import { normalizeFormattedField } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../../constants';
import styled from 'styled-components';
import { InfoIcon } from '../../../../components/Icons';
import { Form, Field, FormSpy } from 'react-final-form';
import { salesAdapter, salesApiSlice as api } from '../../../../services/api/sales';
import moment from 'moment';
import { useState } from 'react';
// @ts-ignore
import setFieldData from 'final-form-set-field-data';
import { ConfirmDropReserveModal } from './ConfirmDropReservePriceModal';

const notesMaxLen = maxLength(50);

const StyledField = styled(Field)`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
  height: 60px;
  div {
    flex-grow: 1;
  }
`;

const StyledTextAreaField = styled(Field)`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  padding-left: 8px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
`;

const StyledHelperText = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 12px;
`;

const HelperTextContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EditVehicleBidModal = ({ form: modalName, initialValues, reset }: any) => {
  const vehicleBids = initialValues?.highest_bid;
  const vehicleReservedPriceMet = initialValues?.reserve_price_met;
  const vehicleTimeEnded = initialValues?.date_end && moment(initialValues.date_end).isBefore(moment());
  const userRole = useSelector((state: any) => state?.user?.user?.role);

  // const [updateVehicle] = useSalesVehicleUpdateMutation();

  const dispatch = useDispatch();

  const { page, perPage, filters } = useSelector((state: any) => state.mySales);

  const [dropReserveModalOpen, setDropReserveModalOpen] = useState(false);

  const submitForm = (values: any) => {
    const { bids, highestBid, highest_bid, awarded_user, current_bid, auction, ...vehicle } = values;

    dispatch(
      updateVehicle({
        ...vehicle,
        cost: normalizeFormattedField(values.cost ?? ''),
        stock: values.stock ?? '',
        notes: values.notes ?? '',
        updateOptions: false,
        reserve_price: normalizeFormattedField(values.reserve_price ?? ''),
        starting_bid: normalizeFormattedField(values.starting_bid ?? ''),
        updateText: 'Vehicle updated',
        refetchMySales: true,
        redirect: false
      }).request
    );

    dispatch(
      // @ts-ignore
      api.util.updateQueryData(
        'getMySales',
        {
          limit: perPage,
          offset: page,
          ...filters
        },
        draft => {
          salesAdapter.updateOne(draft.rows, {
            id: vehicle.id,
            changes: {
              reserve_price: values.reserve_price ? normalizeFormattedField(values.reserve_price ?? '') : null
            }
          });
        }
      )
    );

    setDropReserveModalOpen(false);
  };

  const shouldRenderConfirmModal = (reserve_price: string, highest_bid: string) => {
    const normalizedReservePrice = Number(normalizeFormattedField(reserve_price ?? ''));
    const normalizedHighestBid = Number(normalizeFormattedField(highest_bid ?? ''));
    return normalizedReservePrice <= normalizedHighestBid;
  };

  const handleSubmitButtonClick = (form: any, values: any) => {
    const renderConfirmModal = shouldRenderConfirmModal(values.reserve_price, values.highest_bid?.amount);
    if (!renderConfirmModal || vehicleTimeEnded || vehicleReservedPriceMet) {
      return form.submit(); // submitForm(values);
    }
    setDropReserveModalOpen(true);
  };

  return (
    <Form
      mutators={{ setFieldData }}
      name={modalName}
      onSubmit={submitForm}
      style={{ maxWidth: '367px' }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, values, form }) => {
        return (
          <form id={modalName} onSubmit={handleSubmit}>
            <ModalWrapper
              // @ts-ignore
              title="Edit"
              submitting={submitting}
              handleSubmit={() => handleSubmitButtonClick(form, values)}
              modalId={modalName}
              cancelAdditionalAction={reset}
              className="edit-vehicle-bid-modal"
              actionsClassName="edit-vehicle-bid-modal-actions"
            >
              <Grid container>
                <StyledGrid item xs={12}>
                  <StyledLabel>Stock#</StyledLabel>
                  <StyledField
                    name="stock"
                    // @ts-ignore
                    component={FormInput}
                    placeholder=""
                    disableUnderline
                    validate={notesMaxLen}
                  />
                </StyledGrid>
                <StyledGrid item xs={12}>
                  <StyledLabel>Cost</StyledLabel>
                  <StyledField
                    name="cost"
                    component={FormFormattedField}
                    placeholder=""
                    // @ts-ignore
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale
                    prefix="$"
                    customInput={FormInput}
                    parse={normalizeFormattedField}
                    disableUnderline
                  />
                </StyledGrid>
                <StyledGrid item xs={12}>
                  <StyledLabel>Reserve price</StyledLabel>
                  <StyledField
                    name="reserve_price"
                    component={FormFormattedField}
                    placeholder=""
                    // @ts-ignore
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale
                    prefix="$"
                    customInput={FormInput}
                    disableUnderline
                    parse={normalizeFormattedField}
                    validate={validateReservePrice}
                    disabled={(vehicleTimeEnded || vehicleReservedPriceMet) && userRole === roles.SELLER}
                  />
                </StyledGrid>
                <StyledGrid item xs={12}>
                  <StyledLabel>Starting/Min bid</StyledLabel>
                  <StyledField
                    name="starting_bid"
                    component={FormFormattedField}
                    placeholder="100$"
                    // @ts-ignore
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale
                    prefix="$"
                    customInput={FormInput}
                    parse={normalizeFormattedField}
                    disableUnderline
                    validate={validateMinBid}
                  />
                </StyledGrid>
                <Grid item xs={12}>
                  <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledLabel>Notes</StyledLabel>
                    <HelperTextContainer>
                      <StyledHelperText>Max. 50 characters</StyledHelperText>
                      <InfoIcon />
                    </HelperTextContainer>
                  </Grid>
                  <StyledTextAreaField
                    name="notes"
                    // @ts-ignore
                    component={FormTextAreaField}
                    disableUnderline
                    fullWidth
                    validate={notesMaxLen}
                    rows={4}
                  />
                </Grid>
              </Grid>
            </ModalWrapper>
            <ConfirmDropReserveModal
              isOpen={dropReserveModalOpen}
              closeModal={() => setDropReserveModalOpen(false)}
              buttonsDisabled={submitting}
              handleSubmit={handleSubmit}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                const normalizedReservePrice = Number(values.reserve_price ?? '');
                const normalizedHighestBid = Number(values.highest_bid?.amount ?? '');
                const normalizedStartingBid = Number(values.starting_bid ?? '');

                if (normalizedStartingBid >= normalizedReservePrice) {
                  // this case is handled by error instead
                  form.mutators.setFieldData('reserve_price', {
                    warning: undefined
                  });
                } else {
                  const warning =
                    normalizedReservePrice <= normalizedHighestBid
                      ? 'Reserve dropped the car will be sold.'
                      : undefined;

                  form.mutators.setFieldData('reserve_price', {
                    warning
                  });
                }
              }}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default EditVehicleBidModal;
