import { Vehicle } from '../../../../types/vehicle';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  font-weight: 500;
`;

type Props = {
  vehicle: Vehicle;
};

export const AdminProxyBid = ({ vehicle }: Props) => {
  return (
    <Wrapper>
      <span style={{ fontWeight: 500 }}>
        <NumberFormat displayType="text" prefix="$" value={vehicle.proxy_bid?.amount || 0} thousandSeparator />
      </span>
    </Wrapper>
  );
};
