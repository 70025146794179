import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuctionType } from '../../types/vehicle';

type LiveAuctionState = {
  page: number;
  perPage: number;
  auctionType: AuctionType;
  search?: string;
};

const initialState: LiveAuctionState = {
  page: 1,
  perPage: 24,
  auctionType: AuctionType.AUTOAXESS,
  search: ''
};

const auctionsListSlice = createSlice({
  name: 'auctionsList',
  initialState,

  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setAuctionType(state, action: PayloadAction<AuctionType>) {
      state.auctionType = action.payload;
      state.page = 1;
      state.search = '';
    },
    setSearch(state, action: PayloadAction<string>) {
      state.page = 1;
      state.search = action.payload;
    },
    reset(state) {
      state.page = 1;
      state.perPage = 24;
      state.auctionType = AuctionType.AUTOAXESS;
      state.search = '';
    },
    resetWithoutType(state) {
      state.page = 1;
      state.perPage = 24;
      state.search = '';
    }
  }
});

export const { setPage, setAuctionType, reset, setSearch, resetWithoutType } = auctionsListSlice.actions;
export default auctionsListSlice;
