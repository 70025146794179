import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { useScreen } from 'usehooks-ts';

export type TableButtonType<T> = {
  conditionalDisable?: (data: T) => boolean;
  conditionalRendering?: (data: T) => boolean;
  color?: string;
  title: string;
  handleClick: (data?: T) => any;
  fullWidth?: boolean;
  isRounded?: boolean;
  withShadow?: boolean;
  size?: 'auto' | 'lg';
};

type Props<T> = {
  btn: TableButtonType<T>;
  itemData: T;
};

const StyledButton = styled(Button)<{
  fullWidth?: boolean;
  isRounded?: boolean;
  withShadow?: boolean;
  btnSize: 'lg' | 'auto';
}>`
  height: ${props => (props.btnSize === 'lg' ? '44px' : 'auto')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border-radius: ${props => (props.isRounded ? '6px' : '4px')};
  box-shadow: ${props => (props.withShadow ? '0px 6px 4px rgba(0, 0, 0, 0.25)' : 'none')};
`;

export const TableButton = <T,>({ btn, itemData }: Props<T>) => {
  const { conditionalDisable, conditionalRendering, color, title, size = 'auto', fullWidth = false } = btn;
  const shouldBeDisabled = conditionalDisable && conditionalDisable(itemData);
  const specialColorStyle = (() => {
    if (color === 'error') return { backgroundColor: '#d32f2f', color: '#fff' };
    if (color === 'light') return { backgroundColor: '#3994DE', color: '#fff' };
    return {};
  })();

  const button = (
    <StyledButton
      fullWidth={fullWidth}
      variant="contained"
      // @ts-ignore
      color={color || 'primary'}
      onClick={() => btn.handleClick(itemData)}
      disabled={shouldBeDisabled}
      style={specialColorStyle}
      isRounded={btn.isRounded}
      withShadow={btn.withShadow}
      btnSize={size}
    >
      {title}
    </StyledButton>
  );

  const shouldntBeRendered = conditionalRendering && !conditionalRendering(itemData);
  if (shouldntBeRendered) {
    return null;
  }
  return button;
};
