import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import moment from 'moment-timezone';
import { Table } from '../../../../../components';
import {
  modalsToggle,
  sellersInvoicesSetSeller,
  getSingleInvoice,
  sellersInvoicesSetPage,
  sellersInvoicesSetDate,
  getTotalsInvoice
} from '../../../../../actions';
import { getInvoiceByUploadedCars } from '../../../../../actions/financials';
import { getSellersInvoicesCount, getSellersInvoicesStatistics } from '../../../../../selectors/invoices';

const modalName = 'CurrentInvoiceModal';
const formName = 'invoiceDetailForm';

const StyledTableWrapper = styled.div`
  && {
    tr {
      td:first-child {
        width: 200px;
      }
      th:nth-child(2) {
        max-width: 200px;
        white-space: pre;
      }
      th:nth-child(3) {
        max-width: 200px;
        white-space: pre;
      }
      th:nth-child(4) {
        max-width: 200px;
        white-space: pre;
      }
    }
  }
`;

class InvoicesTable extends Component {
  componentDidMount() {
    const { getTotals } = this.props;

    getTotals();
  }

  render() {
    const {
      setSeller,
      setInvoiceDate,
      getInvoice,
      getInvoiceByCars,
      toggleModal,
      initializeForm,
      loadMore,
      loading,
      page,
      perPage,
      history,
      invoices,
      invoicesCount,
      totalInvoices: {
        uploadedYTD = '',
        soldYTD = '',
        uploadedLastMonth = '',
        soldLastMonth = '',
        uploadedThisMonth = '',
        soldThisMonth = ''
      }
    } = this.props;
    const buttons = [
      {
        title: 'Current',
        color: 'secondary',
        handleClick: item => {
          setSeller(item);
          setInvoiceDate(moment.tz('America/Los_Angeles').format('MM-YYYY'));
          getInvoice({ seller: item.id, date: moment.tz('America/Los_Angeles').format('MM-YYYY') });
          toggleModal(modalName);
          initializeForm({
            due_date: moment()
              .tz('America/Los_Angeles')
              .add(1, 'month')
              .format('MMM 15, YYYY'),
            invoice_date: moment()
              .tz('America/Los_Angeles')
              .format('MMM DD, YYYY')
          });
        }
      },
      {
        title: 'Last Month',
        color: 'secondary',
        handleClick: item => {
          setSeller(item);
          setInvoiceDate(
            moment
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .format('MM-YYYY')
          );
          getInvoice({
            seller: item.id,
            date: moment
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .format('MM-YYYY')
          });
          toggleModal(modalName);
          initializeForm({
            due_date: moment()
              .tz('America/Los_Angeles')
              .format('MMM 15, YYYY'),
            invoice_date: moment()
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .endOf('month')
              .format('MMM DD, YYYY')
          });
        }
      },
      {
        title: 'Unique VIN',
        color: 'secondary',
        handleClick: item => {
          setSeller(item);
          setInvoiceDate(
            moment
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .format('MM-YYYY')
          );
          getInvoiceByCars({
            seller: item.id,
            date: moment
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .format('MM-YYYY')
          });
          toggleModal('InvoiceByUploadedVehiclesModal');
          initializeForm({
            due_date: moment().format('MMM 15, YYYY'),
            invoice_date: moment()
              .tz('America/Los_Angeles')
              .subtract(1, 'month')
              .endOf('month')
              .format('MMM DD, YYYY')
          });
        }
      },
      {
        title: 'History',
        color: 'secondary',
        handleClick: item => {
          setSeller(item);
          history.push(`/admin/financials/invoices/${item.id}`);
        }
      }
    ];
    return (
      <StyledTableWrapper>
        <Table
          columnsHead={[
            { title: 'Seller', key: 'dealership_name' },
            {
              title: `Cars Uploaded YTD${uploadedYTD ? ` (${uploadedYTD})` : ''}/\n Cars Sold YTD${
                soldYTD ? ` (${soldYTD})` : ''
              }`,
              key: 'vehicles_this_year'
            },
            {
              title: `Cars Uploaded Last Month${
                uploadedLastMonth ? ` (${uploadedLastMonth})` : ''
              }/\n Cars Sold Last Month${soldLastMonth ? ` (${soldLastMonth})` : ''}`,
              key: 'vehicles_last_month'
            },
            {
              title: `Cars Uploaded Current Month${
                uploadedThisMonth ? ` (${uploadedThisMonth})` : ''
              }/\n Cars Sold Current Month${soldThisMonth ? ` (${soldThisMonth})` : ''}`,
              key: 'vehicles_this_month'
            },
            { title: '', key: 'actions', cellClass: 'text-center' }
          ]}
          columnsData={invoices}
          rowsCount={invoicesCount}
          buttons={buttons}
          page={page}
          rowsPerPage={perPage}
          loadMore={loadMore}
          loading={loading}
          noDataText=""
        />
      </StyledTableWrapper>
    );
  }
}

const mapStateToProps = state => ({
  invoices: getSellersInvoicesStatistics(state),
  invoicesCount: getSellersInvoicesCount(state) || 0,
  page: parseInt(state.financials.page, 10),
  perPage: parseInt(state.financials.perPage, 10),
  loading: state.financials.loading,
  totalInvoices: state.financials.totalInvoices || {}
});

const mapDispatchToProps = dispatch => ({
  setSeller: data => dispatch(sellersInvoicesSetSeller(data)),
  toggleModal: key => dispatch(modalsToggle(key)),
  getInvoice: data => dispatch(getSingleInvoice(data).request),
  getTotals: data => dispatch(getTotalsInvoice(data || {}).request),
  getInvoiceByCars: data => dispatch(getInvoiceByUploadedCars(data).request),
  setPage: page => dispatch(sellersInvoicesSetPage(page)),
  setInvoiceDate: data => dispatch(sellersInvoicesSetDate(data)),
  initializeForm: data => dispatch(initialize(formName, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable);
