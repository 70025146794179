import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { OfferAuction } from '../../../types/vehicle';
import apiSlice from '..';
import { SocketMessage } from '../types';
import { WS_OFFER_AUCTION_UPDATE } from '../../../constants/actionTypes';
import getSocket from '../../socket';
import { modalsHide, snackShow } from 'actions';

export const vehicleOfferAuctionsAdapter = createEntityAdapter<OfferAuction>();

export const offerAuctionApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    makePrivateOffer: builder.mutation<{ success: boolean }, { notes?: string; vehicleId: number; amount: number }>({
      query: ({ vehicleId, ...body }) => ({
        method: 'POST',
        url: `vehicles/${vehicleId}/offers/make`,
        body
      }),
      invalidatesTags: ['OfferAuction'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          dispatch(modalsHide(`sendVehicleOffer-${args.vehicleId}`));
          await queryFulfilled;
          dispatch(snackShow({ message: 'Offer placed' }));
        } catch (e) {
          dispatch(snackShow({ message: 'Error while making offer', type: 'error' }));
        }
      }
    }),
    stopNegotiation: builder.mutation<{ success: boolean }, { notes?: string; vehicleId: number; amount: number }>({
      query: ({ vehicleId, ...body }) => ({
        method: 'POST',
        url: `vehicles/offers/${vehicleId}/stop-negotiation`,
        body
      }),
      invalidatesTags: ['OfferAuction', 'AuctionVehicles'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          dispatch(modalsHide(`sendVehicleOffer-${args.vehicleId}`));
          await queryFulfilled;
          dispatch(snackShow({ message: 'Offer placed' }));
        } catch (e) {
          dispatch(snackShow({ message: 'Error while making offer', type: 'error' }));
        }
      }
    }),
    acceptPrivateOffer: builder.mutation({
      query: ({ vehicleId }: { vehicleId: number }) => ({
        method: 'POST',
        url: `vehicles/${vehicleId}/offers/accept`
      }),
      invalidatesTags: ['OfferAuction', 'AuctionVehicles'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(modalsHide(`acceptPrivateOffer-${args.vehicleId}`));
          dispatch(snackShow({ message: 'Offer accepted' }));
        } catch (e) {
          dispatch(snackShow({ message: 'Error while making offer', type: 'error' }));
        }
      }
    }),
    getPrivateOfferAmount: builder.query<number | null, { vehicleId: number }>({
      query: ({ vehicleId }) => ({
        method: 'GET',
        url: `vehicles/${vehicleId}/offers/accept`
      }),
      transformResponse: (response: any) => response.data?.amount || null
    }),
    fetchActiveOffers: builder.query<EntityState<OfferAuction>, { vehicleIds: number[] }>({
      query: ({ vehicleIds }) => ({
        method: 'GET',
        url: 'vehicles/offers',
        params: { vehicleIds }
      }),
      forceRefetch: () => true,
      providesTags: ['OfferAuction'],
      transformResponse: (response: any) =>
        vehicleOfferAuctionsAdapter.addMany(
          vehicleOfferAuctionsAdapter.getInitialState(),
          response.data?.map((el: any) => ({ ...el, id: el.vehicle_id })) ?? []
        ),
      async onCacheEntryAdded(args, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState }) {
        const store = getState() as any;
        const userId = store.user?.user?.id;
        if (!userId) return;
        const socket = getSocket();
        for (const vehicleId of args.vehicleIds) {
          socket.emit('subscribe', `vehicleOfferAuction:${vehicleId}`);
        }
        const listener = ({ payload, type }: SocketMessage) => {
          if (type === WS_OFFER_AUCTION_UPDATE) {
            updateCachedData((draft: EntityState<OfferAuction>) => {
              vehicleOfferAuctionsAdapter.upsertOne(draft, { ...payload, id: payload.vehicle_id });
            });
          }
        };
        try {
          await cacheDataLoaded;
          socket.on('message', listener);
        } catch {}
        await cacheEntryRemoved;
        socket.off('message', listener);
      }
    }),
    placeOfferBid: builder.mutation<any, any>({
      query: ({ vehicle_id, ...body }) => ({
        url: `/vehicles/${vehicle_id}/bid-offers/make`,
        method: 'POST',
        body
      })
    }),
    acceptBid: builder.mutation<void, { vehicle_id: number; offer_id: number }>({
      query: ({ vehicle_id, offer_id }) => ({
        url: `/vehicles/${vehicle_id}/bid-offers/accept/${offer_id}`,
        method: 'POST'
      })
    }),
    unawardWithCounter: builder.mutation<void, { vehicleId: number; amount: number; notes: string }>({
      query: ({ vehicleId, amount, notes }) => ({
        url: `vehicles/${vehicleId}/unaward`,
        method: 'POST',
        body: {
          amount,
          notes
        }
      }),
      onQueryStarted: ({ vehicleId }, { dispatch }) => {
        dispatch(modalsHide(`unaward-${vehicleId}`));
      }
    })
  }),
  overrideExisting: true
});

export const legacyOfferAuctionSelector = (state: any, vehicleId: number) => {
  return offerAuctionApiSlice.endpoints.fetchActiveOffers.select({ vehicleIds: [vehicleId] })(state)?.data?.entities?.[
    vehicleId
  ];
};

export const legacyOfferAuctionSelectorWithMultipleIds = (state: any, vehicleIds: number[], vehicleId: number) => {
  return offerAuctionApiSlice.endpoints.fetchActiveOffers.select({ vehicleIds })(state)?.data?.entities?.[vehicleId];
};

export const {
  useMakePrivateOfferMutation,
  useFetchActiveOffersQuery,
  useAcceptPrivateOfferMutation,
  usePlaceOfferBidMutation,
  useAcceptBidMutation,
  useLazyGetPrivateOfferAmountQuery,
  useStopNegotiationMutation,
  useUnawardWithCounterMutation
} = offerAuctionApiSlice;
