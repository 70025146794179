import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Icon,
  ButtonBase
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { modalsToggle } from '../../actions';
import './index.scss';

const StyledCloseButton = styled(ButtonBase)`
  position: absolute;
  top: 8px;
  right: 5px;
`;

const StyledTitle = styled(Typography)`
  white-space: pre-line;
`;

const ModalWrapper = props => {
  const {
    children,
    open,
    modalId,
    toggleModal,
    title,
    submitText,
    cancelText,
    newText,
    handleSubmit,
    handlePressNew,
    submitting,
    showTitle,
    selectedModalId,
    contentClassName,
    showSubmit,
    cancelAdditionalAction,
    className,
    dialogProps = {},
    cancelCustomAction,
    showCancel,
    showNew,
    onEnter,
    onClose,
    actionsClassName = '',
    showAdditionalButton = false,
    additionalButtonText = '',
    additionalButtonAction
  } = props;
  return (
    <Dialog
      className={className}
      open={open && modalId === selectedModalId}
      onClose={() => {
        toggleModal(modalId);
        if (onClose) onClose();
      }}
      onEnter={onEnter}
      style={{ width: '100%' }}
      {...dialogProps}
    >
      {showTitle && (
        <DialogTitle disableTypography>
          <StyledTitle variant="title">{title}</StyledTitle>
          <StyledCloseButton
            onClick={() => {
              toggleModal(modalId);
              cancelAdditionalAction();
              if (onClose) onClose();
            }}
          >
            <Icon>close</Icon>
          </StyledCloseButton>
        </DialogTitle>
      )}
      <DialogContent className={contentClassName}>{children}</DialogContent>
      <DialogActions className={actionsClassName}>
        {showAdditionalButton && (
          <Button onClick={additionalButtonAction} color="primary" variant="outlined">
            {additionalButtonText}
          </Button>
        )}
        {showCancel && (
          <Button
            onClick={
              cancelCustomAction ||
              (() => {
                toggleModal(modalId);
                cancelAdditionalAction();
              })
            }
            color="primary"
            variant="outlined"
          >
            {cancelText}
          </Button>
        )}
        {showSubmit && (
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={submitting}>
            {submitText}
          </Button>
        )}
        {showNew && (
          <Button onClick={handlePressNew} color="primary" variant="contained" disabled={submitting}>
            {newText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.any,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  newText: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  contentClassName: PropTypes.string,
  showSubmit: PropTypes.bool,
  showCancel: PropTypes.bool,
  showTitle: PropTypes.bool,
  showNew: PropTypes.bool,
  cancelAdditionalAction: PropTypes.func,
  handlePressNew: PropTypes.func,
  cancelCustomAction: PropTypes.func,
  onEnter: PropTypes.func,
  onClose: PropTypes.func,
  additionalButtonText: PropTypes.string,
  additionalButtonAction: PropTypes.func,
  showAdditionalButton: PropTypes.bool
};

ModalWrapper.defaultProps = {
  submitText: 'Confirm',
  cancelText: 'Cancel',
  submitting: false,
  contentClassName: 'modal-holder',
  showSubmit: true,
  newText: '',
  showCancel: true,
  showNew: false,
  showTitle: true,
  handleSubmit: () => {},
  cancelAdditionalAction: () => {},
  cancelCustomAction: null,
  children: <></>,
  onEnter: () => {},
  handlePressNew: () => {},
  onClose: null,
  additionalButtonText: '',
  additionalButtonAction: () => {},
  showAdditionalButton: false
};

const mapStateToProps = state => ({
  open: state.modals.open,
  selectedModalId: state.modals.modalId
});

const mapDispatchToProps = dispatch => ({
  toggleModal: modalId => dispatch(modalsToggle(modalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
