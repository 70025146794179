import { Timer } from '../../../../../components';
import { VehicleDocs } from '../../../../../components/TableComponents';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { BidStatus, HighestBidder } from '../index';
import { StyledLabel, StyledText, Wrapper } from './styled';
import { getHighestBid } from '../../../../../utils/vehicle';
import { useSelector } from 'react-redux';
import { OfferStatus } from '../../../../../types/vehicle';
import { useSalesOfferAuction } from '../hooks';
import { useTimerConfig } from '../../../../../hooks';

export const VehicleSecondaryInfoBlock = ({ currentVehicle }) => {
  const user = useSelector(state => state?.user?.user);
  const highestBid = getHighestBid(currentVehicle);
  const offerAuction = useSalesOfferAuction(currentVehicle.id);

  const activeOffer = offerAuction?.privateOffers?.find(offer => offer.status === OfferStatus.SENT) ?? null;
  const counterAmount = activeOffer?.amount ?? 0;
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);
  const sellerAcceptedOffer = offerAuction?.offerBids?.find(el => el.status === 'seller_accepted');

  const { timer, colorsConfig } = useTimerConfig(currentVehicle, offerAuction);
  return (
    <div className="secondary-info-block">
      <Wrapper>
        <StyledLabel>Time Left:</StyledLabel>
        <StyledText>
          {timer != null ? <Timer toDate={timer} withColors colorsConfig={colorsConfig} /> : 'N/A'}
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Status:</StyledLabel>
        <StyledText>
          <BidStatus
            payment_method={currentVehicle.payment_method}
            payment_status={currentVehicle.payment_status}
            status={status}
            awardedAmount={currentVehicle.vehicle_price}
            counterAmount={counterAmount}
            sellerAcceptedAmount={sellerAcceptedOffer?.amount}
          />
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Docs:</StyledLabel>
        <StyledText>
          <VehicleDocs vehicle={currentVehicle} />
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>High Bid:</StyledLabel>
        <StyledText>
          <HighestBidder highestBid={highestBid} inline />
        </StyledText>
      </Wrapper>
    </div>
  );
};
