import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Typography, Grid, InputAdornment } from '@material-ui/core';
import { FormAutocompleteSelect, FormDatePicker, FormTextField } from '../../../../components/Form';
import { ModalWrapper } from '../../../../components';
import { roles } from '../../../../constants';
import { listUsers, modalsToggle, listBuyerFees } from '../../../../actions';
import { auctionDetailAwardBuyer } from '../../../../actions/vehicles';
import { required } from '../../../../utils/validators';
import { getBiddersAndUsers, getIndexedBiddersAndUsers } from '../../../../selectors/auction-details';
import AwardVehicleChargeCreditCardPicker from './AwardVehicleChargeCreditCardPicker';
import { getUsers } from '../../../../selectors/users';
import { offerAuctionApiSlice as api } from 'services/api/offer-auction';

const formName = 'auctionDetailAwardBuyerForm';
const modalName = 'auctionDetailAwardBuyerModal';

const StyledGrid = styled(Grid)`
  min-width: 500px;
  margin-top: 24px;
`;

const StyledSelectField = styled(Field)`
  margin-bottom: 10px;
`;

class ChargeBuyerModal extends Component {
  constructor() {
    super();
    this.state = { buyerHasCreditCard: false };
  }

  componentDidMount() {
    const { fetchBuyers, getFees } = this.props;
    fetchBuyers();
    getFees();
  }

  charge = values => {
    const { vehicle, toggle, awardVehicleBuyer, reset } = this.props;

    const data = {
      vehicle_id: vehicle.id,
      buyer_id: values.user_id ? values.user_id.value : vehicle.buyer_id,
      awardPrice: +values.award_price,
      charge_credit_card: values.charge_credit_card,
      processing_fee: +values.processing_fee || 0,
      transportation_fee: +values.transportation_fee || 0,
      seller_fee: +values.seller_fee || 0,
      award_date: (values.award_date ?? new Date()).toISOString()
    };

    awardVehicleBuyer(data);
    toggle(modalName);
    reset();
  };

  initializePrice = () => {
    const { initialize, auctionVehicle, changeFormField } = this.props;
    const sellerFee = auctionVehicle.user?.seller?.fee;

    initialize({
      award_price: '',
      charge_credit_card: 'no',
      processing_fee: '0',
      transportation_fee: '0',
      seller_fee: sellerFee != null ? sellerFee : '125',
      award_date: new Date()
    });
    const { preSelectedBuyer, predefinedAwardPrice } = auctionVehicle;
    if (!preSelectedBuyer) {
      this.setState({ buyerHasCreditCard: false });
    }
    if (preSelectedBuyer) {
      changeFormField(formName, 'user_id', preSelectedBuyer);
      this.handleBuyerChange(preSelectedBuyer.value);
    }
    if (predefinedAwardPrice) {
      changeFormField(formName, 'award_price', predefinedAwardPrice);
      this.handleAwardPriceChange(null, predefinedAwardPrice);
    }
  };

  handleBuyerChange = userId => {
    const { fullUsers, changeFormField } = this.props;
    const selectedUser = fullUsers.find(user => user.id === userId);
    const buyerHasCreditCard = selectedUser && selectedUser.buyer && selectedUser.buyer.customer_profile_id;
    this.setState({ buyerHasCreditCard });
    changeFormField(formName, 'charge_credit_card', 'no');
  };

  handleAwardPriceChange = (_, value) => {
    const { fees, changeFormField } = this.props;
    const matchingFee = fees.find(fee => +value >= +fee.min_amount && +value <= +fee.max_amount);
    if (matchingFee && matchingFee.fee) {
      changeFormField(formName, 'processing_fee', matchingFee.fee);
    }
  };

  render() {
    const {
      vehicle,
      reset,
      users,
      transportation_fee: transportationFee,
      processing_fee: processingFee,
      seller_fee: sellerFee,
      auctionVehicle,
      handleSubmit,
      adminUser
    } = this.props;

    const { buyerHasCreditCard } = this.state;

    const isAdminOrManager = ['Admin', 'Manager'].includes(adminUser.job_title);

    const usdAmountInputProps = {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
      autoComplete: 'off'
    };

    const { preSelectedBuyer } = auctionVehicle;

    return (
      <ModalWrapper
        modalId={modalName}
        title="Award Vehicle"
        submitText="Award"
        handleSubmit={handleSubmit(this.charge)}
        // submitting={!vehicle.buyer_id}
        cancelAdditionalAction={reset}
        onEnter={this.initializePrice}
      >
        <Typography variant="body2">Car: {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
        <Typography variant="body2">
          VIN:
          {vehicle.vin}
        </Typography>
        <Typography variant="body2">Seller: {vehicle.user.dealership_name}</Typography>

        <StyledGrid container alignItems="center">
          <Grid item xs={12} sm={5}>
            Award Date:
          </Grid>
          <Grid item xs={12} sm={7}>
            <Field
              name="award_date"
              component={FormDatePicker}
              label="Select Date"
              fullWidth
              disabled={!isAdminOrManager}
              validate={required}
              maxMenuHeight={200}
              defaultValue={new Date()}
            />
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item xs={12} sm={5}>
            Buyer:
          </Grid>
          <Grid item xs={12} sm={7}>
            <StyledSelectField
              name="user_id"
              component={FormAutocompleteSelect}
              label="Select Buyer"
              options={users}
              fullWidth
              select
              validate={required}
              maxMenuHeight={200}
              onChange={e => this.handleBuyerChange(e.value)}
              isDisabled={!!preSelectedBuyer}
            />
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Charge Credit Card:
          </Grid>
          <Grid item sm={7} xs={12}>
            <AwardVehicleChargeCreditCardPicker buyerHasCreditCard={buyerHasCreditCard} />
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Award Price:
          </Grid>
          <Grid item sm={7} xs={12}>
            <Field
              name="award_price"
              component={FormTextField}
              label="Award Price"
              fullWidth
              validate={[required]}
              InputProps={usdAmountInputProps}
              onChange={this.handleAwardPriceChange}
            />
          </Grid>
        </StyledGrid>

        <>
          <StyledGrid container alignItems="center">
            <Grid item sm={5} xs={12}>
              Processing Fee:
            </Grid>
            <Grid item sm={7} xs={12}>
              <Field
                name="processing_fee"
                component={FormTextField}
                label="Processing Fee"
                fullWidth
                type="number"
                InputProps={usdAmountInputProps}
              />
            </Grid>
          </StyledGrid>

          <StyledGrid container alignItems="center">
            <Grid item sm={5} xs={12}>
              Transportation Fee:
            </Grid>
            <Grid item sm={7} xs={12}>
              <Field
                name="transportation_fee"
                component={FormTextField}
                label="Transportation Fee"
                fullWidth
                type="number"
                InputProps={usdAmountInputProps}
              />
            </Grid>
          </StyledGrid>

          <StyledGrid container alignItems="center">
            <Grid item sm={5} xs={12}>
              Seller Fee:
            </Grid>
            <Grid item sm={7} xs={12}>
              <Field
                name="seller_fee"
                disabled={!isAdminOrManager}
                component={FormTextField}
                label="Seller Fee"
                fullWidth
                type="number"
                InputProps={usdAmountInputProps}
              />
            </Grid>
          </StyledGrid>

          <StyledGrid container>
            <Grid item sm={5} xs={12} />
            <Grid item sm={7} xs={12}>
              <hr style={{ display: 'none' }} />
            </Grid>
          </StyledGrid>

          <StyledGrid container alignItems="center">
            <Grid item sm={5} xs={12}>
              TOTAL FEE:
            </Grid>
            <Grid item sm={7} xs={12}>
              <Typography color="primary">${+processingFee + +transportationFee}</Typography>
            </Grid>
          </StyledGrid>
        </>
      </ModalWrapper>
    );
  }
}

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  users: getBiddersAndUsers(state),
  fullUsers: getUsers(state),
  adminUser: state.user.user,
  indexedUsers: getIndexedBiddersAndUsers(state),
  fees: state.financials.fees,
  award_price: selector(state, 'award_price'),
  charge_credit_card: selector(state, 'charge_credit_card'),
  processing_fee: selector(state, 'processing_fee'),
  seller_fee: selector(state, 'seller_fee'),
  transportation_fee: selector(state, 'transportation_fee'),
  auctionVehicle: state.auctions.auctionVehiclesModal
});

const mapDispatchToProps = dispatch => ({
  getFees: () => dispatch(listBuyerFees().request),
  toggle: key => dispatch(modalsToggle(key)),
  fetchBuyers: () =>
    dispatch(
      listUsers({
        params: { role: roles.BUYER }
      }).request
    ),
  changeFormField: (form, field, value) => dispatch(change(form, field, value)),
  awardVehicleBuyer: data => dispatch(auctionDetailAwardBuyer(data).request)
});

export default compose(
  reduxForm({
    form: formName
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(ChargeBuyerModal);
