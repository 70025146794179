import { Vehicle } from '../../../../types/vehicle';
import NumberFormat from 'react-number-format';
import { useAdminOfferAuction } from '../hooks';
import { useOfferAuctionBidsInfo } from '../../../../hooks';
import styled from 'styled-components';
import { ButtonBase, Icon, Popover } from '@material-ui/core';
import { useState } from 'react';
import { ViewAllBidsTooltip } from '../../../Shared/VehicleDetails/components/BidHistorySection/components/ViewAllBidsModal';
import { convertToLocalTime } from '../../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  font-weight: 500;
`;

const StyledBackButton = styled(ButtonBase)`
  margin-top: auto;
`;

type Props = {
  vehicle: Vehicle;
};

export const AdminOffers = ({ vehicle }: Props) => {
  const offerAuction = useAdminOfferAuction(vehicle.id);
  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction);
  const proxyOfferBid = offerAuction?.offerProxyBid;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const vehicleOfferBids = (offerAuction?.offerBids || []).map(bid => ({
    id: bid.id,
    bid: bid.amount,
    bidder: bid.bidder?.dealership_name,
    date: convertToLocalTime(bid.created_at, true),
    created_at: bid.created_at
  }));

  return (
    <Wrapper>
      <span style={{ fontWeight: 500 }}>
        <NumberFormat displayType="text" prefix="$" value={highestBidOffer?.amount ?? 0} thousandSeparator />
      </span>

      {vehicleOfferBids.length > 0 && (
        <>
          <StyledBackButton onClick={handleOpenPopover}>
            <Icon>expand_more</Icon>
          </StyledBackButton>
          <Popover
            id="offer-history-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            marginThreshold={150}
          >
            <ViewAllBidsTooltip
              bids={vehicleOfferBids}
              vehicleId={vehicle.id}
              type={'offers'}
              proxyBid={proxyOfferBid}
              form={`adminEditBidModalForm-offers`}
            />
          </Popover>
        </>
      )}
    </Wrapper>
  );
};
